import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import {
    service_one,
    service_two,
    service_third,
    service_fourth,
    service_fifth,
    service_sixth,
    service_seventh
} from "../components/Images";

const Service_slider = () => {
    const services = [
        { imgSrc: service_one, label: "Grooming", path: "/groominglisting" },
        { imgSrc: service_two, label: "Day Care",  path:"/daycarelisting"},
        { imgSrc: service_third, label: "Boarding",path:"/boardinglisting" },
        { imgSrc: service_fourth, label: "Veterinary",path:"/verterinarylisting" },
        { imgSrc: service_fifth, label: "Pet Shop", path:"/clothing_pet_shop" },
        { imgSrc: service_sixth, label: "Adoptions", path:"/adoption-mainpage" },
        { imgSrc: service_seventh, label: "Dog Friendly",path:"/petlisting" },
    ];

    var settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 7,
        slidesToScroll: 1,
        margin: '10px',
        autoplay: true,
        autoplaySpeed: 3000,
        padding: '0',
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <Slider {...settings}>
            {services.map((service, index) => (
                <div key={index} className="slide-item">
                   <Link to={service.path}>
                        <img src={service.imgSrc} alt={service.label} />
                    </Link>
                    <p>{service.label}</p>
                </div>
            ))}
        </Slider>
    );
}

export default Service_slider;
