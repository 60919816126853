import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { FaStar, FaRegStarHalfStroke, FaLocationDot } from "react-icons/fa6";
import { LuMinus } from "react-icons/lu";
import { TbFileUpload } from "react-icons/tb";
import { GoPlus } from "react-icons/go";
import { product_pic } from "../components/Images";
import { Link } from 'react-router-dom';
const BookNew = ({ booksession, setbooksession }) => {

  const [fileName, setFileName] = useState("");

  const handleClose = () => setbooksession(false);

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      setFileName(event.target.files[0].name);
    }
  };


  return (
    <>
      <Modal show={booksession} onHide={handleClose} className='booknow'>
        <div className='modelheader'>
          <h1 className='main_heading'>Book Your<br></br>
            <span>Pets Care Session.</span></h1>
          <p className='sub_heading'>Please select date and timings, service type for book your session. Also Upload pet documents.</p>
        </div>
        <Modal.Body>
          <div className='select_dete_time'>
            <select className='create_pet_for_gender_in_login'>
              <option>Service Type</option>
              <option>In Store</option>
              <option>Pick & Drop</option>
            </select>
            <input type='date'></input>
            <input type='time'></input>
          </div>
          <div>
            <h2 className='documnet'>Vaccination Documents</h2>
            <p className='document_show'>(or Show Later Physically)</p>
          </div>
          <div className='upload_section'>
            {fileName && <p className='file-name'>{fileName}</p>}
            <label htmlFor="file-upload" className="custom-file-upload">
              <TbFileUpload />   Upload Document
            </label>
            <input id="file-upload" type="file" onChange={handleFileChange} />

          </div>
        </Modal.Body>

        <div className='model_footer'>
          <Link to="/grooming-personaldetail">
          <button className='booknow_btn'>Book Now</button>
          </Link>
        </div>

      </Modal>
    </>
  )
}


export default BookNew;