import React, { useState } from "react";
import './login.css'
import { useNavigate } from 'react-router-dom';
function Login() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = () => {

    navigate('/login-user-otp'); 
  }
  return (
    <div className="container-fluid vh-100 d-flex justify-content-center align-items-center bg-dark position-relative">
      <div
        className="row bg-white rounded-lg overflow-hidden frisbee_login_main_section"
        style={{ width: "90%", maxWidth: "800px" }}
      >
        <div className="col-md-6 p-4 text-white d-flex flex-column justify-content-center">
          <div
            style={{ backgroundColor: "#C73633" }}
            className="frisbee_main_section_div"
          >
            <div className="frisbee_main_section_div_child">
              <span>Get Expert</span>
              <span className="login_professional_servies">
                Professional Services
              </span>
              <span>At Your Door Step.</span>
            </div>
            <img
              src="/frisbeeImage/login.png"
              alt="Service"
              className="img-fluid mt-3 rounded-lg"
            />
          </div>
        </div>
        <div className="col-md-6 p-4 d-flex flex-column justify-content-center frisbee_login_right_section_main">
          <img
            src="/frisbeeImage/login_top.png"
            alt="Logo"
            className="img-fluid mb-3 mx-auto"
            style={{ maxHeight: "60px" }}
          />
          <h4 className="text-center">
            Welcome To <span style={{ color: "#C73633" }}>Frisbee</span>
          </h4>
          <span className="text-center text-muted login_quic_affordable">
            Quick • Affordable • Trusted
          </span>
          <form>
            <div className="form-group login_right_side_form">
              <label htmlFor="mobileNumber" className="font-weight-bold">
                Mobile Number
              </label>
              <div className="input-group login_input_for_mobile_no">
                <div className="input-group-prepend">
                  <button
                    className="btn btn-outline-secondary dropdown-toggle"
                    type="button"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <img
                      src="/frisbeeImage/login_flag.png"
                      alt="Flag"
                      style={{ width: "20px" }}
                    />
                  </button>
                  <div className={`dropdown-menu login_main_dropdown_class ${isOpen ? "show" : ""}`}>
                    <a className="dropdown-item" href="#" >
                      <img
                        src="/frisbeeImage/login_flag.png"
                        alt="Flag"
                        style={{ width: "20px" }}
                      />{" "}
                      <span>Country 1</span>
                    </a>
                    <a className="dropdown-item" href="#">
                      <img
                        src="/frisbeeImage/login_flag.png"
                        alt="Flag"
                        style={{ width: "20px" }}
                      />{" "}
                      <span>Country 2</span>
                    </a>
                  </div>
                </div>
                <span className="login_straight_line">|</span>
                <input
                  type="number"
                  className="form-control"
                  id="mobileNumber"
                  placeholder="Enter Mobile Number"
                />
              </div>
            </div>
            <div className="d-flex align-items-center my-3 login_for_hr">
              <hr className="flex-grow-1" />
              <span className="mx-2">Or</span>
              <hr className="flex-grow-1" />
            </div>
            <div className="d-flex justify-content-around mb-3 login_right_side_btn_section">
              <button
                type="button"
                className="btn btn-outline-secondary btn-block"
                onClick={handleSubmit}>
                <img
                  src="/frisbeeImage/login_ggogle.png"
                  alt="Google"
                  style={{ width: "20px" }}
                />{" "}
                <span>Google</span>
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary btn-block"
              >
                <img
                  src="/frisbeeImage/login_apple.png"
                  alt="Apple"
                  style={{ width: "20px" }}
                />{" "}
                Apple
              </button>
            </div>
          </form>
          <p className="text-center text-muted login_term_And_condation_bottom">
            By clicking continue with Google and Apple, you agree to the
            <a href="#" className="text-primary">
              {" "}
              Terms of Use{" "}
            </a>{" "}
            and
            <a href="#" className="text-primary">
              {" "}
              Privacy Policy
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
}

export default Login;
