import { grooimg_firstimg } from "../components/Images";
import { FaRegStarHalfStroke } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import { FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";
const Petshoplisting = () => {
    return (
        <>
            <div className="avenue_pet">
                <Link to="/petshop_storedetails">
                    <div className="pet_avenue">
                        <img src={grooimg_firstimg} />
                        <p>20% OFF</p>

                    </div>
                    <div className="rightside_list">
                        <div className="add_review">
                            <ul>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaRegStarHalfStroke /></li>

                            </ul>
                            <span>4.2</span>
                        </div>
                        <h1>Pet Avenue</h1>
                        <p className="mx-0">It is a long established fact that a reader will be distracted by content...</p>
                        <div>
                            <p className="mx-0"> <FaLocationDot id="location" /> Dubai Al Warsan 3, Birds and Pets Market</p>
                        </div>
                    </div>
                    <div>
                        <button>Book Now</button>
                    </div>
                </Link>

            </div>

            <div className="avenue_pet">
                <Link to="/">
                    <div className="pet_avenue">
                        <img src={grooimg_firstimg} />
                        <p>20% OFF</p>

                    </div>
                    <div className="rightside_list">
                        <div className="add_review">
                            <ul>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaRegStarHalfStroke /></li>

                            </ul>
                            <span>4.2</span>
                        </div>
                        <h1>Pet Avenue</h1>
                        <p className="mx-0">It is a long established fact that a reader will be distracted by content...</p>
                        <div>
                            <p className="mx-0"> <FaLocationDot id="location" /> Dubai Al Warsan 3, Birds and Pets Market</p>
                        </div>
                    </div>
                    <div>
                        <button>Book Now</button>
                    </div>
                </Link>

            </div>

            <div className="avenue_pet">
                <Link to="/">
                    <div className="pet_avenue">
                        <img src={grooimg_firstimg} />
                        <p>20% OFF</p>

                    </div>
                    <div className="rightside_list">
                        <div className="add_review">
                            <ul>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaRegStarHalfStroke /></li>

                            </ul>
                            <span>4.2</span>
                        </div>
                        <h1>Pet Avenue</h1>
                        <p className="mx-0">It is a long established fact that a reader will be distracted by content...</p>
                        <div>
                            <p className="mx-0"> <FaLocationDot id="location" /> Dubai Al Warsan 3, Birds and Pets Market</p>
                        </div>
                    </div>
                    <div>
                        <button>Book Now</button>
                    </div>
                </Link>

            </div>

            <div className="avenue_pet">
                <Link to="/">
                    <div className="pet_avenue">
                        <img src={grooimg_firstimg} />
                        <p>20% OFF</p>

                    </div>
                    <div className="rightside_list">
                        <div className="add_review">
                            <ul>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaRegStarHalfStroke /></li>

                            </ul>
                            <span>4.2</span>
                        </div>
                        <h1>Pet Avenue</h1>
                        <p className="mx-0">It is a long established fact that a reader will be distracted by content...</p>
                        <div>
                            <p className="mx-0"> <FaLocationDot id="location" /> Dubai Al Warsan 3, Birds and Pets Market</p>
                        </div>
                    </div>
                    <div>
                        <button>Book Now</button>
                    </div>
                </Link>

            </div>

            <div className="avenue_pet">
                <Link to="/">
                    <div className="pet_avenue">
                        <img src={grooimg_firstimg} />
                        <p>20% OFF</p>

                    </div>
                    <div className="rightside_list">
                        <div className="add_review">
                            <ul>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaRegStarHalfStroke /></li>

                            </ul>
                            <span>4.2</span>
                        </div>
                        <h1>Pet Avenue</h1>
                        <p className="mx-0">It is a long established fact that a reader will be distracted by content...</p>
                        <div>
                            <p className="mx-0"> <FaLocationDot id="location" /> Dubai Al Warsan 3, Birds and Pets Market</p>
                        </div>
                    </div>
                    <div>
                        <button>Book Now</button>
                    </div>
                </Link>

            </div>

            <div className="avenue_pet">
                <Link to="/">
                    <div className="pet_avenue">
                        <img src={grooimg_firstimg} />
                        <p>20% OFF</p>

                    </div>
                    <div className="rightside_list">
                        <div className="add_review">
                            <ul>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaRegStarHalfStroke /></li>

                            </ul>
                            <span>4.2</span>
                        </div>
                        <h1>Pet Avenue</h1>
                        <p className="mx-0">It is a long established fact that a reader will be distracted by content...</p>
                        <div>
                            <p className="mx-0"> <FaLocationDot id="location" /> Dubai Al Warsan 3, Birds and Pets Market</p>
                        </div>
                    </div>
                    <div>
                        <button>Book Now</button>
                    </div>
                </Link>

            </div>

            <div className="avenue_pet">
                <Link to="/">
                    <div className="pet_avenue">
                        <img src={grooimg_firstimg} />
                        <p>20% OFF</p>

                    </div>
                    <div className="rightside_list">
                        <div className="add_review">
                            <ul>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaRegStarHalfStroke /></li>

                            </ul>
                            <span>4.2</span>
                        </div>
                        <h1>Pet Avenue</h1>
                        <p className="mx-0">It is a long established fact that a reader will be distracted by content...</p>
                        <div>
                            <p className="mx-0"> <FaLocationDot id="location" /> Dubai Al Warsan 3, Birds and Pets Market</p>
                        </div>
                    </div>
                    <div>
                        <button>Book Now</button>
                    </div>
                </Link>

            </div>

            <div className="avenue_pet">
                <Link to="/">
                    <div className="pet_avenue">
                        <img src={grooimg_firstimg} />
                        <p>20% OFF</p>

                    </div>
                    <div className="rightside_list">
                        <div className="add_review">
                            <ul>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaRegStarHalfStroke /></li>

                            </ul>
                            <span>4.2</span>
                        </div>
                        <h1>Pet Avenue</h1>
                        <p className="mx-0">It is a long established fact that a reader will be distracted by content...</p>
                        <div>
                            <p className="mx-0"> <FaLocationDot id="location" /> Dubai Al Warsan 3, Birds and Pets Market</p>
                        </div>
                    </div>
                    <div>
                        <button>Book Now</button>
                    </div>
                </Link>

            </div>

            <div className="avenue_pet">
                <Link to="/">
                    <div className="pet_avenue">
                        <img src={grooimg_firstimg} />
                        <p>20% OFF</p>

                    </div>
                    <div className="rightside_list">
                        <div className="add_review">
                            <ul>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaRegStarHalfStroke /></li>

                            </ul>
                            <span>4.2</span>
                        </div>
                        <h1>Pet Avenue</h1>
                        <p className="mx-0">It is a long established fact that a reader will be distracted by content...</p>
                        <div>
                            <p className="mx-0"> <FaLocationDot id="location" /> Dubai Al Warsan 3, Birds and Pets Market</p>
                        </div>
                    </div>
                    <div>
                        <button>Book Now</button>
                    </div>
                </Link>

            </div>

            <div className="avenue_pet">
                <Link to="/">
                    <div className="pet_avenue">
                        <img src={grooimg_firstimg} />
                        <p>20% OFF</p>

                    </div>
                    <div className="rightside_list">
                        <div className="add_review">
                            <ul>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaRegStarHalfStroke /></li>

                            </ul>
                            <span>4.2</span>
                        </div>
                        <h1>Pet Avenue</h1>
                        <p className="mx-0">It is a long established fact that a reader will be distracted by content...</p>
                        <div>
                            <p className="mx-0"> <FaLocationDot id="location" /> Dubai Al Warsan 3, Birds and Pets Market</p>
                        </div>
                    </div>
                    <div>
                        <button>Book Now</button>
                    </div>
                </Link>

            </div>

            <div className="avenue_pet">
                <Link to="/">
                    <div className="pet_avenue">
                        <img src={grooimg_firstimg} />
                        <p>20% OFF</p>

                    </div>
                    <div className="rightside_list">
                        <div className="add_review">
                            <ul>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaRegStarHalfStroke /></li>

                            </ul>
                            <span>4.2</span>
                        </div>
                        <h1>Pet Avenue</h1>
                        <p className="mx-0">It is a long established fact that a reader will be distracted by content...</p>
                        <div>
                            <p className="mx-0"> <FaLocationDot id="location" /> Dubai Al Warsan 3, Birds and Pets Market</p>
                        </div>
                    </div>
                    <div>
                        <button>Book Now</button>
                    </div>
                </Link>

            </div>
        </>
    )
}
export default Petshoplisting;