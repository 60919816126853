import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { FaRegStarHalfStroke } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { Latestnews } from "../components/Images";
const Latest_news = () => {
    var settings = {
        dots: false,
        arrow: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        Margin: '10',
        Padding: '10',
        cssEase: 'linear',
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 768, 
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
          ]
    };
    return (
        <>
            <Slider {...settings}>

                <div className="slide-item">
                    <div className="grooming_board">

                        <div className="grooming_board_cardbox">

                            <img src={Latestnews} />

                        </div>
                        <div className="">
                            <h1 className="location_heading">How to take Better Care </h1>
                            <div className="location_creation">

                            <Link to="">
                                    <p className="mx-0">Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse pariatur duis deserunt mollit...<span>READ MORE </span></p>
                                </Link>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="slide-item">
                    <div className="grooming_board">

                        <div className="grooming_board_cardbox">

                            <img src={Latestnews} />

                        </div>
                        <div className="">
                            <h1 className="location_heading">How to take Better Care </h1>
                            <div className="location_creation">
                            <Link to="">
                                    <p className="mx-0">Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse pariatur duis deserunt mollit...<span>READ MORE </span></p>
                                </Link>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="slide-item">
                    <div className="grooming_board">

                        <div className="grooming_board_cardbox">
                            <img src={Latestnews} />
                        </div>
                        <div className="">
                            <h1 className="location_heading">How to take Better Care </h1>
                            <div className="location_creation">
                            <Link to="">
                                    <p className="mx-0">Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse pariatur duis deserunt mollit...<span>READ MORE </span></p>
                                </Link>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="slide-item">
                    <div className="grooming_board">

                        <div className="grooming_board_cardbox">
                            <img src={Latestnews} />

                        </div>
                        <div className="">
                            <h1 className="location_heading">How to take Better Care </h1>
                            <div className="location_creation">
                                <Link to="">
                                    <p className="mx-0">Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse pariatur duis deserunt mollit...<span>READ MORE </span></p>
                                </Link>
                            </div>

                        </div>
                    </div>

                </div>



            </Slider>
        </>
    )
}

export default Latest_news;