import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { sendOtp } from "../../controllers/accounts/Account"
import { generateToken } from '../../controllers/firebase/firebaseController';
import { Link, useNavigate } from "react-router-dom";
import NewLoginUserOtp from "../loginUserOtp/newLoginUserOtp";
import {
  validationSchema,
  otpSchema,
  validationSchemaForInvidual,
  validationSchemaForUser,
  otpSchemaUser,
} from "../../validators/vendorFormValidator";


function LoginNew({ isOpen, onClose }) {
  //here is newLoginUserOTP code
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);

  const handleOpenOtpModal = () => {
    onClose();  
    setIsOtpModalOpen(true);
  };

  const handleCloseOtpModal = () => {
    setIsOtpModalOpen(false);
  };

  const [isOpenDropdown, setIsOpenSetDropDown] = useState(false);

  // Alam
  const [formData, setFormData] = useState({
    mobile: "",
    otp: "",
    remember_me: false
  });
  const navigate = useNavigate();
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState({});
  const [countdown, setCountdown] = useState(60);
  const [isCounting, setIsCounting] = useState(false);


  const handleChange = (e) => {
    setFormData((preVal) => ({
      ...preVal,
      [e.target.name]: e.target.value,
    }));
    setErrors((preError) => ({
      ...preError,
      [e.target.name]: "",
    }));
  };

  /*********************************************************
   *  This function is use to validate form data before send otp
   *********************************************************/
  //   const validateFormData = async(formData) =>{
  //     const numberRegex = /^[0-9]+$/;
  //     const phone_code = country_code || '+971'
  //     if (
  //       formData.get("mobileNumber") === "undefined" ||
  //       formData.get("mobileNumber") === null ||
  //       formData.get("mobileNumber").trim() === ""||
  //       !numberRegex.test(formData.get("mobileNumber"))
  //     ) {
  //         setErrors((preError) => ({
  //           ...preError,
  //           mobileNumber: "Enter a valid mobile number",
  //         }));
  //       return false;
  //     } else if ( 
  //       phone_code === '+971' && String(formData.get("mobileNumber")).length !== 9
  //     ) {
  //         setErrors((preError) => ({
  //           ...preError,
  //           mobileNumber: "mobile number should be 9 digits",
  //         }));
  //       return false;
  //     } else if ( 
  //       phone_code === '+91' && String(formData.get("mobileNumber")).length !== 10
  //     ) {
  //         setErrors((preError) => ({
  //           ...preError,
  //           mobileNumber: "mobile number should be 10 digits",
  //         }));
  //       return false;
  //   }
  // }


  const handleSendOtp = async () => {
    try {
      if (!formData.mobileNumber) {
        setErrors((preError) => ({
          ...preError,
          mobileNumber: 'mobile number is required',
        }));
      } else {
        const options = { mobile: formData.mobileNumber, country_code: '+' + 91, };
        const result = await sendOtp(options);
        if (result.status === true) {
          setIsOTPSent(true);
          setSuccess((preSuccess) => ({
            ...preSuccess,
            formSuccess: result.message
          }));
          setCountdown(60);
          setIsCounting(true);
        } else {
          setIsOTPSent(false);
          setErrors((preError) => ({
            ...preError,
            formError: result.message,
          }));
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div>

      {isOpen && (
        <div
          className="modal fade show"
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div
            className="modal-dialog modal-dialog-centered login_modal_popup"
            style={{ maxWidth: "58%" }}
          >
            <div className="modal-content">
              <div className="modal-body login_popup_modal_body">
                <div className="row">
                  <div className="col-md-6 p-4 text-white d-flex flex-column justify-content-center">
                    <div
                      style={{ backgroundColor: "#C73633" }}
                      className="frisbee_main_section_div"
                    >
                      <div className="frisbee_main_section_div_child">
                        <span>Get Expert</span>
                        <span className="login_professional_servies">
                          Professional Services
                        </span>
                        <span>At Your Door Step.</span>
                      </div>
                      <img
                        src="/frisbeeImage/login.png"
                        alt="Service"
                        className="img-fluid mt-3 rounded-lg"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 p-4 d-flex flex-column justify-content-center frisbee_login_right_section_main">
                    <img
                      src="/frisbeeImage/login_top.png"
                      alt="Logo"
                      className="img-fluid mb-3 mx-auto"
                      style={{ maxHeight: "60px" }}
                    />
                    <h4 className="text-center">
                      Welcome To{" "}
                      <span style={{ color: "#C73633" }}>Frisbee</span>
                    </h4>
                    <span className="text-center text-muted login_quic_affordable">
                      Quick • Affordable • Trusted
                    </span>
                    <form>
                      <div className="form-group login_right_side_form">
                        <label
                          htmlFor="mobileNumber"
                          className="font-weight-bold"
                        >
                          Mobile Number
                        </label>
                        <div className="input-group login_input_for_mobile_no">
                          <div className="input-group-prepend">
                            <button
                              className="btn btn-outline-secondary dropdown-toggle"
                              type="button"
                              onClick={() =>
                                setIsOpenSetDropDown(!isOpenDropdown)
                              }
                            >
                              <img
                                src="/frisbeeImage/login_flag.png"
                                alt="Flag"
                                style={{ width: "20px" }}
                              />
                            </button>
                            <div
                              className={`dropdown-menu login_main_dropdown_class ${isOpenDropdown ? "show" : ""
                                }`}
                            >
                              <a className="dropdown-item" href="#">
                                <img
                                  src="/frisbeeImage/login_flag.png"
                                  alt="Flag"
                                  style={{ width: "20px" }}
                                />{" "}
                                <span>IND (+91)</span>
                              </a>
                              <a className="dropdown-item" href="#">
                                <img
                                  src="/frisbeeImage/login_flag.png"
                                  alt="Flag"
                                  style={{ width: "20px" }}
                                />{" "}
                                <span>UAE</span>
                              </a>
                            </div>
                          </div>
                          <span className="login_straight_line">|</span>
                          <div>
                            <input
                              type="number"
                              className="form-control"
                              id="mobileNumber"
                              placeholder="Enter Mobile Number"
                              name="mobileNumber"
                              onChange={handleChange}
                              value={formData.mobileNumber}
                            />

                          </div>
                          <button className="login_sent_button" type="button" onClick={handleOpenOtpModal}>
                            <img
                              src="/frisbeeImage/ion_paper-plane.png"
                              alt="Send"
                              className="send-icon"
                            />
                          </button>
                        </div>
                      </div>

                      <div className="d-flex align-items-center my-3 login_for_hr">
                        <hr className="flex-grow-1" />
                        <span className="mx-2">Or</span>
                        <hr className="flex-grow-1" />
                      </div>

                      <div className="d-flex justify-content-around mb-3 login_right_side_btn_section">
                        <button
                          type="button"
                          className="btn btn-outline-secondary btn-block"
                        >
                          <img
                            src="/frisbeeImage/login_ggogle.png"
                            alt="Google"
                            style={{ width: "20px" }}
                          />{" "}
                          <span>Google</span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-secondary btn-block"
                        >
                          <img
                            src="/frisbeeImage/login_apple.png"
                            alt="Apple"
                            style={{ width: "20px" }}
                          />{" "}
                          Apple
                        </button>
                      </div>
                    </form>
                    <p className="text-center text-muted login_term_And_condation_bottom">
                      By clicking continue with Google and Apple, you agree to
                      the
                      <a href="#" className="text-primary">
                        {" "}
                        Terms of Use{" "}
                      </a>{" "}
                      and
                      <a href="#" className="text-primary">
                        {" "}
                        Privacy Policy
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
              <div className="position-absolute user_login_top_right_cross_btn">
                <button className="top-0 end-0 m-3" onClick={onClose}>
                  x
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <NewLoginUserOtp isOpen={isOtpModalOpen} handleCloseModal={handleCloseOtpModal} />
    </div>
  );
}

export default LoginNew;
