import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { FaStar, FaRegStarHalfStroke, FaLocationDot } from "react-icons/fa6";
import { LuMinus } from "react-icons/lu";
import { GoPlus } from "react-icons/go";
import { product_pic } from "../components/Images";
import { Link } from 'react-router-dom';
const Cartpop = () => {
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);

  const [quantity, setquantity] = useState(1);
  const incrementQuantity = () => {
    setquantity(prevQuantity => prevQuantity + 1);
  };

  const decrementQuantity = () => {
    setquantity(prevQuantity => (prevQuantity > 1 ? prevQuantity - 1 : 1));
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} id="cartpop">
        <Modal.Header closeButton>

          <div >
            <div className='character_stics'>
              <p>Your Shopping Cart</p>
            </div>
            <div className='pop-header mt-3'>
              <p>Log in or create an account to enjoy a personalized shopping experience and speed up future purchases.</p>
              <button className="sign_up">Sign Up / Sign In</button>
            </div>

          </div>
        </Modal.Header>

        <Modal.Body id="product_item">
          <div className='character_stics'>
            <p>Cart Items</p>
          </div>
          <div className='product_item'>
            <div className='cart_view'>
              <img src={product_pic} alt="cart_item" />
            </div>

            <div className="item_info">
              <div className="add_review">
                <ul>
                  <li><FaStar /></li>
                  <li><FaStar /></li>
                  <li><FaStar /></li>
                  <li><FaStar /></li>
                  <li><FaRegStarHalfStroke /></li>

                </ul>
                <span>4.2</span>
              </div>
              <h1>Pedigree Beef Chunks </h1>
              <div className="product_size">
                <p className="gram_productsize mb-2">500 g</p>
                <p className="kilo_productsize mb-2">1kg</p>
              </div>
              <div className="product_price">
                <div>
                  <p className='mb-2'>AED 500</p>
                  <strike className="mx-0">AED65.50</strike>
                </div>
                <div className='cart_plus'>
                  <button className="cart_min" onClick={decrementQuantity}><LuMinus /></button>
                  <p className='cart-item'>{quantity < 10 ? `0${quantity}` : quantity}</p>
                  <button className='cartplus' onClick={incrementQuantity}><GoPlus /></button>
                </div>
              </div>
            </div>
          </div>
          <div className='product_item'>
            <div className='cart_view'>
              <img src={product_pic} alt="cart_item" />
            </div>

            <div className="item_info">
              <div className="add_review">
                <ul>
                  <li><FaStar /></li>
                  <li><FaStar /></li>
                  <li><FaStar /></li>
                  <li><FaStar /></li>
                  <li><FaRegStarHalfStroke /></li>

                </ul>
                <span>4.2</span>
              </div>
              <h1>Pedigree Beef Chunks </h1>
              <div className="product_size">
                <p className="gram_productsize mb-2">500 g</p>
                <p className="kilo_productsize mb-2">1kg</p>
              </div>
              <div className="product_price">
                <div>
                  <p className='mb-2'>AED 500</p>
                  <strike className="mx-0">AED65.50</strike>
                </div>
                <div className='cart_plus'>
                  <button className="cart_min" onClick={decrementQuantity}><LuMinus /></button>
                  <p className='cart-item'>{quantity < 10 ? `0${quantity}` : quantity}</p>
                  <button className='cartplus' onClick={incrementQuantity}><GoPlus /></button>
                </div>
              </div>
            </div>
          </div>
          <div className='product_item'>
            <div className='cart_view'>
              <img src={product_pic} alt="cart_item" />
            </div>

            <div className="item_info">
              <div className="add_review">
                <ul>
                  <li><FaStar /></li>
                  <li><FaStar /></li>
                  <li><FaStar /></li>
                  <li><FaStar /></li>
                  <li><FaRegStarHalfStroke /></li>

                </ul>
                <span>4.2</span>
              </div>
              <h1>Pedigree Beef Chunks </h1>
              <div className="product_size">
                <p className="gram_productsize mb-2">500 g</p>
                <p className="kilo_productsize mb-2">1kg</p>
              </div>
              <div className="product_price">
                <div>
                  <p className='mb-2'>AED 500</p>
                  <strike className="mx-0">AED65.50</strike>
                </div>
                <div className='cart_plus'>
                  <button className="cart_min" onClick={decrementQuantity}><LuMinus /></button>
                  <p className='cart-item'>{quantity < 10 ? `0${quantity}` : quantity}</p>
                  <button className='cartplus' onClick={incrementQuantity}><GoPlus /></button>
                </div>
              </div>
            </div>
          </div>
          <div className='product_item'>
            <div className='cart_view'>
              <img src={product_pic} alt="cart_item" />
            </div>

            <div className="item_info">
              <div className="add_review">
                <ul>
                  <li><FaStar /></li>
                  <li><FaStar /></li>
                  <li><FaStar /></li>
                  <li><FaStar /></li>
                  <li><FaRegStarHalfStroke /></li>

                </ul>
                <span>4.2</span>
              </div>
              <h1>Pedigree Beef Chunks </h1>
              <div className="product_size">
                <p className="gram_productsize mb-2">500 g</p>
                <p className="kilo_productsize mb-2">1kg</p>
              </div>
              <div className="product_price">
                <div>
                  <p className='mb-2'>AED 500</p>
                  <strike className="mx-0">AED65.50</strike>
                </div>
                <div className='cart_plus'>
                  <button className="cart_min" onClick={decrementQuantity}><LuMinus /></button>
                  <p className='cart-item'>{quantity < 10 ? `0${quantity}` : quantity}</p>
                  <button className='cartplus' onClick={incrementQuantity}><GoPlus /></button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <div className='cart_footer'>
          <div className='subtotal'>
            <p>Sub Total</p>
            <h1>AED 100.00</h1>
          </div>
          <div className='cartfooter'>
            <button variant="secondary" className="checkout" onClick={handleClose}>
              <Link to="/devlivery_process">   Proceed to Checkout</Link>

            </button>
            <button variant="primary" className='view_cart' onClick={handleClose}>
              <Link to="/devlivery_process">    View Your Cart</Link>
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}
export default Cartpop;