import React, { useRef, useState } from "react";
import "./createPetProfile.css";
import Header from "../Header";
import { GoPlus } from "react-icons/go";
import Appdownaload from "../../Home/Appdowanload";
import Footer from "../Footer";
import { Link, useNavigate } from 'react-router-dom';
const CreatePetProfile = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   console.log("Selected file:", file);
  // };
  const handleSubmit = () => {

    navigate('/create-pet-parent-name');
  }



  const [petForms, setPetForms] = useState([{}]);

  const handleAddPet = () => {
    setPetForms([...petForms, {}]);
  };

  const handleFileChange = (event, index) => {
    const files = event.target.files;
    // Handle file change for the specific pet form
  };

  return (
    <>
      <Header />
      <div>
        <div className="container">
          <div className="row mt-5">
            <div className="col-lg-9 ">
              <div className="create_pet_profile_main_div_div">
                <div className="create_pet_profile_for_skip_for_now">
                  <div className="create_pet_profile_for_pet_profile">
                    <h2>
                      Create Your{" "}
                      <span className="text-danger fw-bold">Pet Profile</span>
                    </h2>
                    <p>
                      It is a long established fact that a reader will be distracted by the readable content
                    </p>
                  </div>
                  <div className="login_user_otp_for_bottom_button_skip">
                    <button className="btn btn-outline-danger user_otp_second" onClick={handleSubmit}>
                      Skip for Now
                    </button>
                  </div>
                </div>

                <div className="create_pet_profile_main_section">
                  {petForms.map((_, index) => (
                    <div
                      key={index}
                      className="row cretae_pet_profile_form_div_sec"
                      style={{ marginBottom: '20px' }} // Adding a 10px gap between sections
                    >
                      <div className="col-lg-9">
                        <h4>Add Pets Details</h4>
                        <form>
                          <div className="row mb-3 ">
                            <div className="col-md-6 mb-3 mb-md-0 create_pet_for_profile_input">
                              <input
                                type="text"
                                className="form-control store_input_field_field_for_Create_pet"
                                placeholder="Pet Name"
                              />
                            </div>
                            <div className="col-md-6 create_pet_for_profile_input">
                              <input
                                type="text"
                                className="form-control store_input_field_field_for_Create_pet"
                                placeholder="Pet Age"
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-md-6 mb-3 mb-md-0 create_pet_for_profile_input">
                              <input
                                type="text"
                                className="form-control store_input_field_field_for_Create_pet"
                                placeholder="Breed"
                              />
                            </div>
                            <div className="col-md-6 create_pet_for_profile_input ">
                              <select className="form-control store_input_field_field_for_Create_pet create_pet_for_gender_in_login">
                                <option>Gender</option>
                                <option>Male</option>
                                <option>Female</option>
                              </select>
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-12 create_pet_for_profile_input">
                              <input
                                type="text"
                                className="form-control store_input_field_field_for_Create_pet"
                                placeholder="Location"
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="col-lg-3">
                        <div className="upload_container d-flex justify-content-center align-items-center">
                          <div
                            className="upload_box d-flex flex-column justify-content-center align-items-center"
                            onClick={() => document.getElementById(`fileInput${index}`).click()}
                          >
                            <input
                              type="file"
                              id={`fileInput${index}`}
                              style={{ display: "none" }}
                              onChange={(e) => handleFileChange(e, index)}
                            />
                            <div className="upload_icon">
                              <GoPlus className="upload_icon" />
                            </div>
                            <div className="upload_text">
                              Upload <br />
                              Pet Picture
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="login_user_otp_for_bottom_button_create_pet">
                  <button className="btn btn-outline-danger user_otp_second" onClick={handleAddPet}>
                    + Add More Pets
                  </button>
                  <button className="btn btn-danger btn-lg user_otp_first">
                    Submit
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-3 p-4 create_pet_profile_right_section_get_expert text-white d-flex flex-column justify-content-center">
              <div
                style={{ backgroundColor: "#C73633" }}
                className="frisbee_main_section_div"
              >
                <div className="frisbee_main_section_div_child_create_pet_profile">
                  <span>Get Expert</span>
                  <span className="login_professional_servies">
                    Professional Services
                  </span>
                  <span>At Your Door Step.</span>
                </div>
                <img
                  src="/frisbeeImage/login.png"
                  alt="Service"
                  className="img-fluid mt-3 rounded-lg"
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="download-section container-fluid py-5">
          <div className="row align-items-center">
            <div className="col-md-8 text-center create_pet_profile_downlaod_the_app">
              <h2 className="download-title">DOWNLOAD THE APP</h2>
              <p className="download-subtitle">
                Book <span className="text-bold">professional Experts</span>{" "}
                from your phone.
              </p>
              <div className="d-flex justify-content-center mt-3">
                <img src="/frisbeeImage/create_bottom_app_pic.png" alt="" />
              </div>
            </div>
            <div className="col-md-4 text-center create_pet_profile_downlaod_mobile_app">
              <img
                src="/frisbeeImage/left_imge_create_pet.png"
                alt="App Preview"
                className="app-preview-img"
              />
            </div>
          </div>
        </div> */}
        <div className="app_dowanlad">
    <Appdownaload></Appdownaload>
</div>
        <Footer></Footer>
      
      </div>
    </>
  );
};

export default CreatePetProfile;
