import { Link } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import { service_one } from "../components/Images";
import { service_two } from "../components/Images";
import { service_third } from "../components/Images";
import { service_fourth } from "../components/Images";
import { service_sixth } from "../components/Images";
import { service_seventh } from "../components/Images";
import {service_fifth} from  "../components/Images";
import { service_eigth } from '../components/Images';
const Shop_Categories = () =>{
    return(
        <>
          <Accordion defaultActiveKey="0">
           <Accordion.Item eventKey="0">
        <Accordion.Header>Services</Accordion.Header>
        <Accordion.Body>
        <ul className='services_names'>
            <li>
                <Link to="/">
                <span>
            <img src={service_one} />
            </span>
            Grooming</Link>
            </li>
            <li className='active'>
                <Link to="/">
                <span>
            <img src={service_two} />
            </span>
            Pet Shop</Link>
            </li>
            <li>
                <Link to="/">
                <span>
            <img src={service_third} />
            </span>
            Day Care</Link>
            </li>
            <li>
                <Link to="/">  
                <span> 
            <img src={service_fourth} />
            </span>
            Boarding</Link></li>

            <li>
                <Link to="/">
                <span>
            <img src={service_sixth} />
            </span>
            Veterinary</Link>
            </li>
            <li>
                <Link to="/">
                <span>
            <img src={service_seventh} />
            </span>
            Adoptions</Link>
            </li>
            <li>
                <Link to="/">
                <span>
            <img src={service_fifth} />
            </span>
            Dog Friendly
            </Link>
            </li>
            <li>
                <span>
                <img src={service_eigth} />
                </span>
        
                <Link to="/">
            Training</Link></li>
        </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Fulfilment Type</Accordion.Header>
        <Accordion.Body>
        <ul className='services_names'>
            <li>
                <Link to="/">
                <span>
            <img src={service_one} />
            </span>
            Grooming</Link>
            </li>
            <li>
                <Link to="/">
                <span>
            <img src={service_two} />
            </span>
            Pet Shop</Link>
            </li>
            <li>
                <Link to="/">
                <span>
            <img src={service_third} />
            </span>
            Day Care</Link>
            </li>
            <li>
                <Link to="/">  
                <span> 
            <img src={service_fourth} />
            </span>
            Boarding</Link></li>

            <li>
                <Link to="/">
                <span>
            <img src={service_sixth} />
            </span>
            Veterinary</Link>
            </li>
            <li>
                <Link to="/">
                <span>
            <img src={service_seventh} />
            </span>
            Adoptions</Link>
            </li>
            <li>
                <Link to="/">
                <span>
            <img src={service_fifth} />
            </span>
            Dog Friendly
            </Link>
            </li>
            <li>
                <span>
                <img src={service_eigth} />
                </span>
        
                <Link to="/">
            Training</Link></li>
        </ul>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2">
        <Accordion.Header>Size</Accordion.Header>
        <Accordion.Body>
        <ul className='services_names'>
            <li>
                <Link to="/">
                <span>
            <img src={service_one} />
            </span>
            Grooming</Link>
            </li>
            <li>
                <Link to="/">
                <span>
            <img src={service_two} />
            </span>
            Pet Shop</Link>
            </li>
            <li>
                <Link to="/">
                <span>
            <img src={service_third} />
            </span>
            Day Care</Link>
            </li>
            <li>
                <Link to="/">  
                <span> 
            <img src={service_fourth} />
            </span>
            Boarding</Link></li>

            <li>
                <Link to="/">
                <span>
            <img src={service_sixth} />
            </span>
            Veterinary</Link>
            </li>
            <li>
                <Link to="/">
                <span>
            <img src={service_seventh} />
            </span>
            Adoptions</Link>
            </li>
            <li>
                <Link to="/">
                <span>
            <img src={service_fifth} />
            </span>
            Dog Friendly
            </Link>
            </li>
            <li>
                <span>
                <img src={service_eigth} />
                </span>
        
                <Link to="/">
            Training</Link></li>
        </ul>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3">
        <Accordion.Header>Offers</Accordion.Header>
        <Accordion.Body>
        <ul className='services_names'>
            <li>
                <Link to="/">
                <span>
            <img src={service_one} />
            </span>
            Grooming</Link>
            </li>
            <li>
                <Link to="/">
                <span>
            <img src={service_two} />
            </span>
            Pet Shop</Link>
            </li>
            <li>
                <Link to="/">
                <span>
            <img src={service_third} />
            </span>
            Day Care</Link>
            </li>
            <li>
                <Link to="/">  
                <span> 
            <img src={service_fourth} />
            </span>
            Boarding</Link></li>

            <li>
                <Link to="/">
                <span>
            <img src={service_sixth} />
            </span>
            Veterinary</Link>
            </li>
            <li>
                <Link to="/">
                <span>
            <img src={service_seventh} />
            </span>
            Adoptions</Link>
            </li>
            <li>
                <Link to="/">
                <span>
            <img src={service_fifth} />
            </span>
            Dog Friendly
            </Link>
            </li>
            <li>
                <span>
                <img src={service_eigth} />
                </span>
        
                <Link to="/">
            Training</Link></li>
        </ul>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
        </>
    )
}
export default  Shop_Categories;