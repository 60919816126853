// import Header from "../components/Header";
import Header from "../../components/Header";
// import Footer from "../components/Footer";
import Footer from "../../components/Footer";
// import ShopBanner from "./ShopBanner";
import ShopBanner from "../../PetShop/ShopBanner";
// import GroomingDownload from "../Grooming_listing/GroomingDownload";
import GroomingDownload from "../../Grooming_listing/GroomingDownload";
// import Sidebar from "../components/sidebar";
import Sidebar from "../../components/sidebar";
import { Link } from "react-router-dom";
import { grooimg_firstimg } from "../../components/Images";
import { FaRegStarHalfStroke } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import { FaStar } from "react-icons/fa";

const Health_categories = () => {

    return (
        <>
            <Header />
            <div className="inner_mainbanner">
                <ShopBanner></ShopBanner>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <Sidebar></Sidebar>
                    </div>
                    <div className="col-md-9 grooming_info">
                        <div className="features_grooming ">
                            <h1 className="main_heading" >Our <span>Health Care </span></h1>
                            <p className="sub_heading">It is a long established fact that a reader will be distracted by the readable content</p>
                        </div>

                        <div className="avenue_pet">
                            <Link to="/shop-productlisting">
                                <div className="pet_avenue">
                                    <img src={grooimg_firstimg} />
                                    <p>20% OFF</p>

                                </div>
                                <div className="rightside_list">
                                    <div className="add_review">
                                        <ul>
                                            <li><FaStar /></li>
                                            <li><FaStar /></li>
                                            <li><FaStar /></li>
                                            <li><FaStar /></li>
                                            <li><FaRegStarHalfStroke /></li>

                                        </ul>
                                        <span>4.2</span>
                                    </div>
                                    <h1>Pet Avenue</h1>
                                    <p className="mx-0">It is a long established fact that a reader will be distracted by content...</p>
                                    <div>
                                        <p className="mx-0"> <FaLocationDot id="location" /> Dubai Al Warsan 3, Birds and Pets Market</p>
                                    </div>
                                </div>
                                <div>
                                    <button>Order Now</button>
                                </div>
                            </Link>

                        </div>

                        <div className="avenue_pet">
                            <Link to="/">
                                <div className="pet_avenue">
                                    <img src={grooimg_firstimg} />
                                    <p>20% OFF</p>

                                </div>
                                <div className="rightside_list">
                                    <div className="add_review">
                                        <ul>
                                            <li><FaStar /></li>
                                            <li><FaStar /></li>
                                            <li><FaStar /></li>
                                            <li><FaStar /></li>
                                            <li><FaRegStarHalfStroke /></li>

                                        </ul>
                                        <span>4.2</span>
                                    </div>
                                    <h1>Pet Avenue</h1>
                                    <p className="mx-0">It is a long established fact that a reader will be distracted by content...</p>
                                    <div>
                                        <p className="mx-0"> <FaLocationDot id="location" /> Dubai Al Warsan 3, Birds and Pets Market</p>
                                    </div>
                                </div>
                                <div>
                                    <button>Order Now</button>
                                </div>
                            </Link>

                        </div>

                        <div className="avenue_pet">
                            <Link to="/">
                                <div className="pet_avenue">
                                    <img src={grooimg_firstimg} />
                                    <p>20% OFF</p>

                                </div>
                                <div className="rightside_list">
                                    <div className="add_review">
                                        <ul>
                                            <li><FaStar /></li>
                                            <li><FaStar /></li>
                                            <li><FaStar /></li>
                                            <li><FaStar /></li>
                                            <li><FaRegStarHalfStroke /></li>

                                        </ul>
                                        <span>4.2</span>
                                    </div>
                                    <h1>Pet Avenue</h1>
                                    <p className="mx-0">It is a long established fact that a reader will be distracted by content...</p>
                                    <div>
                                        <p className="mx-0"> <FaLocationDot id="location" /> Dubai Al Warsan 3, Birds and Pets Market</p>
                                    </div>
                                </div>
                                <div>
                                    <button>Order Now</button>
                                </div>
                            </Link>

                        </div>

                        <div className="avenue_pet">
                            <Link to="/">
                                <div className="pet_avenue">
                                    <img src={grooimg_firstimg} />
                                    <p>20% OFF</p>

                                </div>
                                <div className="rightside_list">
                                    <div className="add_review">
                                        <ul>
                                            <li><FaStar /></li>
                                            <li><FaStar /></li>
                                            <li><FaStar /></li>
                                            <li><FaStar /></li>
                                            <li><FaRegStarHalfStroke /></li>

                                        </ul>
                                        <span>4.2</span>
                                    </div>
                                    <h1>Pet Avenue</h1>
                                    <p className="mx-0">It is a long established fact that a reader will be distracted by content...</p>
                                    <div>
                                        <p className="mx-0"> <FaLocationDot id="location" /> Dubai Al Warsan 3, Birds and Pets Market</p>
                                    </div>
                                </div>
                                <div>
                                    <button>Order Now</button>
                                </div>
                            </Link>

                        </div>
                        <div className="avenue_pet">
                            <Link to="/">
                                <div className="pet_avenue">
                                    <img src={grooimg_firstimg} />
                                    <p>20% OFF</p>

                                </div>
                                <div className="rightside_list">
                                    <div className="add_review">
                                        <ul>
                                            <li><FaStar /></li>
                                            <li><FaStar /></li>
                                            <li><FaStar /></li>
                                            <li><FaStar /></li>
                                            <li><FaRegStarHalfStroke /></li>

                                        </ul>
                                        <span>4.2</span>
                                    </div>
                                    <h1>Pet Avenue</h1>
                                    <p className="mx-0">It is a long established fact that a reader will be distracted by content...</p>
                                    <div>
                                        <p className="mx-0"> <FaLocationDot id="location" /> Dubai Al Warsan 3, Birds and Pets Market</p>
                                    </div>
                                </div>
                                <div>
                                    <button>Order Now</button>
                                </div>
                            </Link>

                        </div>

                    </div>
                </div>
            </div>
            <div className="grooming_download" id="groomingdownload_info">
                <GroomingDownload></GroomingDownload>
            </div>



            <Footer />
        </>
    )
}
export default Health_categories;