import Header from "../components/Header";
import Footer from "../components/Footer";
import { FaStar, FaRegStarHalfStroke, FaLocationDot,FaWhatsapp,FaPhone} from "react-icons/fa6";
import { Link } from "react-router-dom";
import { dog_listone,dog_listsecond,doglist_three,dog_listfour ,icon_first,icon_second,icon_third} from "../components/Images";
import { GoDotFill } from "react-icons/go";
import Appdownaload from "../Home/Appdowanload";
const Adoptiondog_detail = () =>{
    return(
        <>
        <Header></Header>
        <div className="adopt_dogdetails">
        <div className="container">
            <div className="row">
                <div className="col-md-9">
                    <div className="grooming_board">
                        <div className="adoptionstore_details">
                       <div>
                        <img src={dog_listfour} />
                       </div>
                       <div>
                          <h1>Skudo</h1>
                          <p> <FaLocationDot id="location" /> Dubai Al Warsan 3, Birds and Pets Market</p>
                          <ul className="dogdetails_info">
                            <li>
                                <div>
                                    <img src={icon_first}/>
                                </div>
                                <div className="doginfo_dtailsinnersection">
                                <p>Breed</p> 
                                <h1> Husky</h1>
                                </div>
                               
                            </li>
                            <li>
                            <div>
                                <img src={icon_second}/>
                                </div>
                                <div>
                                <p> Age</p> 
                                <h1> 1 Year 5 Months</h1>
                                </div>
                            </li>
                            <li>
                            <div>
                                <img src={icon_third}/>
                            </div>
                            <div>
                                <p> Gender</p> 
                                <h1> Male</h1>
                                </div>
                            </li>
                          </ul>
                        
                          <ul className="connect_btn">
                            <button className="whatsapp_icon"><Link to="/"><FaWhatsapp /> Connect On Whatsapp</Link></button>
                            <button className="call"><Link to="/"><FaPhone /> Connect On Call</Link></button>
                          </ul>
                          <button className="adopt_now">Adopt Now</button>
                       </div>
                        </div>

                        <p className="inner_sec mt-3">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
                        <div className="character_stics">
                            <h2>Physical Characteristics</h2>
                        </div>

                        <p className="inner_sec mt-3">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
                        <div className="character_stics">
                            <h2>Care Tips</h2>
                        </div>
                        <p className="inner_sec mt-3">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.
                            <br></br>
                            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.
                        </p>
                    </div>
                </div>
                <div className="col-md-3 px-0">
                <div className="grooming_board">
             
             <div className="grooming_board_cardbox"> 
                 <div className="offer_discount">
                     <h1>20%</h1> <p>off</p>
                     </div>         
             <img src={dog_listfour} />
             <div className="add_review">
                 <ul>
                     <li><FaStar /></li>
                     <li><FaStar /></li>
                     <li><FaStar /></li>
                     <li><FaStar /></li>
                     <li><FaRegStarHalfStroke /></li>
                    
                 </ul>
                 <span>4.2</span>
             </div>
             </div>
             <div className="">
             <h1 className="location_heading">Pet Avenue</h1>
             <div className="location_creation">
             <p className="delivery_info"><span>In-Store | Delivery</span><br></br>
             Open (10:00 AM - 07:00 PM) </p>
             <Link to="/adoptiondog_detail">
                 <FaLocationDot id="location" /> 
                 <div className="direction_details">
              
                 <p>Dubai Al Warsan 3, Birds....</p>
                 <p className="get_direction"> Get Direction</p>
                 </div>
             
                 </Link>
             </div>
           
           </div>
         </div>
                </div>
            </div>
          </div>
        </div>
        <div className="app_dowanlad">
    <Appdownaload></Appdownaload>
</div>
       
               
                    <div className="container adopt_detailes">
        <div className="row my-3">
                <div className="col-md-9 grooming_info">
                        <div className="features_grooming ">
                            <h1 className="main_heading" >Our   <span>Dog’s </span> List</h1>
                            <p className="sub_heading" >It is a long established fact that a reader will be distracted by the readable content</p> 
                        </div>
                    </div>
                    <div className="col-md-3">
                        
                    
                    </div>
                   
                </div>
        <div className="row ">
                <div className="col-md-3">
                <div className="grooming_board">
             <Link to="/adoptiondog-detail">
             <div className="grooming_board_cardbox"> 
            
            <img src={dog_listone} />
            </div>
            <div className="">
            <h1 className="location_heading">Skudo</h1>
            <div className="location_creation">
             <p className="time_adoptionpet">1 year 8 months</p>
             <p className="time_adoptionpet">Husky <span><GoDotFill id="dots" /> </span> Male</p>
            </div>
          
          </div>
             </Link>
          
         </div>
                </div>
                <div className="col-md-3">
                <div className="grooming_board">
                <Link to="/adoptiondog-detail">
             <div className="grooming_board_cardbox"> 
            
            <img src={dog_listfour} />
            </div>
            <div className="">
            <h1 className="location_heading">Skudo</h1>
            <div className="location_creation">
             <p className="time_adoptionpet">1 year 8 months</p>
             <p className="time_adoptionpet">Husky <span><GoDotFill id="dots" /> </span> Male</p>
            </div>
          
          </div>
             </Link>
          
         </div>
                </div>
                <div className="col-md-3">
                <div className="grooming_board">
                <Link to="/adoptiondog-detail">
             <div className="grooming_board_cardbox"> 
            
            <img src={dog_listone} />
            </div>
            <div className="">
            <h1 className="location_heading">Skudo</h1>
            <div className="location_creation">
             <p className="time_adoptionpet">1 year 8 months</p>
             <p className="time_adoptionpet">Husky <span><GoDotFill id="dots" /> </span> Male</p>
            </div>
          
          </div>
             </Link>
          
         </div>
                </div>
                <div className="col-md-3">
                <div className="grooming_board">
                <Link to="/adoptiondog-detail">
             <div className="grooming_board_cardbox"> 
            
            <img src={doglist_three} />
            </div>
            <div className="">
            <h1 className="location_heading">Skudo</h1>
            <div className="location_creation">
             <p className="time_adoptionpet">1 year 8 months</p>
             <p className="time_adoptionpet">Husky <span><GoDotFill id="dots" /> </span> Male</p>
            </div>
          
          </div>
             </Link>
          
         </div>
                </div>
            </div>
        </div>
       
        
        <Footer></Footer>
        </>
    )
}
export default Adoptiondog_detail;