import React, { useRef } from "react";
import "./createPetProfile.css";
import Header from "../Header";
import { GoPlus } from "react-icons/go";
import Appdownaload from "../../Home/Appdowanload";
import { Link, useNavigate } from 'react-router-dom';
const CreatePetProfileSecondOne = () => {
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };
  const navigate = useNavigate();
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log("Selected file:", file);
  };
  const handleSubmit = () => {


  }

  return (
    <>
      <Header />
      <div>
        <div class="container">
          <div className="row mt-5">
            <div class="col-lg-9">
              <div>
                <div className="create_pet_profile_main_div_div">
                  <div className="create_pet_profile_for_skip_for_now">
                    <div className="create_pet_profile_for_pet_profile">
                      <h2>
                        Create Your{" "}
                        <span className="text-danger fw-bold">Pet Profile</span>
                      </h2>
                      <p>
                        It is a long established fact that a reader will be
                        distracted by the readable content
                      </p>
                    </div>
                  </div>
                  <div className="create_pet_profile_main_section">
                    <div className="row cretae_pet_profile_form_div_sec">
                      <div className="col-lg-9">
                        <h4>Add Pets Details</h4>
                        <form>
                          <div className="row mb-3 ">
                            <div className="col-md-6 mb-3 mb-md-0 create_pet_for_profile_input">
                              <input
                                type="text"
                                className="form-control store_input_field_field_for_Create_pet"
                                placeholder="Pet Name"
                              />
                            </div>
                            <div className="col-md-6 create_pet_for_profile_input">
                              <input
                                type="text"
                                className="form-control store_input_field_field_for_Create_pet"
                                placeholder="Pet Age"
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-md-6 mb-3 mb-md-0 create_pet_for_profile_input">
                              <input
                                type="text"
                                className="form-control store_input_field_field_for_Create_pet"
                                placeholder="Breed"
                              />
                            </div>
                            <div className="col-md-6 create_pet_for_profile_input">
                              <select className="form-control store_input_field_field_for_Create_pet create_pet_for_gender_in_login">
                                <option>Gender</option>
                                <option>Male</option>
                                <option>Female</option>
                              </select>
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-12 create_pet_for_profile_input">
                              <input
                                type="text"
                                className="form-control store_input_field_field_for_Create_pet"
                                placeholder="Location"
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="col-lg-3">
                        <div className="upload_container d-flex justify-content-center align-items-center">
                          <div className="upload_box_for_animal d-flex flex-column justify-content-center align-items-center">
                            <img
                              src="/frisbeeImage/animal_cretae_pet.png"
                              alt=""
                            />
                            {/* <div className="upload_icon">
                              <GoPlus />
                            </div>
                            <div className="upload_text ">
                              Upload <br />
                              Pet Picture
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-4 mt-4">
                  <div className="  create_pet_profile_main_div_div">
                    <div className="create_pet_profile_for_skip_for_now"></div>
                    <div className="create_pet_profile_main_section">
                      <div className="row cretae_pet_profile_form_div_sec">
                        <div className="col-lg-9">
                          <h4>Add Pets Details</h4>
                          <form>
                            <div className="row mb-3 ">
                              <div className="col-md-6 mb-3 mb-md-0 create_pet_for_profile_input">
                                <input
                                  type="text"
                                  className="form-control store_input_field_field_for_Create_pet"
                                  placeholder="Pet Name"
                                />
                              </div>
                              <div className="col-md-6 create_pet_for_profile_input">
                                <input
                                  type="text"
                                  className="form-control store_input_field_field_for_Create_pet"
                                  placeholder="Pet Age"
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-md-6 mb-3 mb-md-0 create_pet_for_profile_input">
                                <input
                                  type="text"
                                  className="form-control store_input_field_field_for_Create_pet"
                                  placeholder="Breed"
                                />
                              </div>
                              <div className="col-md-6 create_pet_for_profile_input">
                                <select className="form-control store_input_field_field_for_Create_pet create_pet_for_gender_in_login">
                                  <option>Gender</option>
                                  <option>Male</option>
                                  <option>Female</option>
                                </select>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-12 create_pet_for_profile_input">
                                <input
                                  type="text"
                                  className="form-control store_input_field_field_for_Create_pet"
                                  placeholder="Location"
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                        <div className="col-lg-3">
                          <div className="upload_container d-flex justify-content-center align-items-center">
                            <div
                              className="upload_box d-flex flex-column justify-content-center align-items-center"
                              onClick={handleClick}
                            >
                              <input
                                type="file"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                                style={{ display: "none" }}
                              />
                              <div className="upload_icon">
                                <GoPlus className="upload_icon" />
                              </div>
                              <div className="upload_text">
                                Upload <br />
                                Pet Picture
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="login_user_otp_for_bottom_button_create_pet">
                <button className="btn btn-outline-danger user_otp_second">
                  + Add More Pets
                </button>
                <Link to="/create-pet-parent-name">
                  <button className="btn btn-danger btn-lg  user_otp_first" >
                    Submit
                  </button>
                </Link>
              </div>
            </div>
            <div className="col-lg-3 p-4 text-white d-flex flex-column create_pet_profile_right_section_get_expert">
              <div
                style={{ backgroundColor: "#C73633" }}
                className="frisbee_main_section_div"
              >
                <div className="frisbee_main_section_div_child_create_pet_profile">
                  <span>Get Expert</span>
                  <span className="login_professional_servies">
                    Professional Services
                  </span>
                  <span>At Your Door Step.</span>
                </div>
                <img
                  src="/frisbeeImage/login.png"
                  alt="Service"
                  className="img-fluid mt-3 rounded-lg"
                />
              </div>
            </div>
          </div>
        </div>
        <Appdownaload></Appdownaload>
      </div>
    </>
  );
};

export default CreatePetProfileSecondOne;
