import React, { useState } from "react";
import { FaUser, FaPhone, FaEnvelope, FaRegTrashAlt } from "react-icons/fa";
import Header from "../../components/Header";
import { testimonial_img } from "../../components/Images";
import Footer from "../../components/Footer";
import { FaLocationDot } from "react-icons/fa6";
import { CiLocationOn } from "react-icons/ci";
import { MdCall } from "react-icons/md";
import { FaStar } from "react-icons/fa";
import { FaRegStarHalfStroke } from "react-icons/fa6";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
function Boarding_Pickup_checkout() {
    const [count, setCount] = useState(0);
    const [rating, setRating] = useState(0);
    const navigate = useNavigate();
    const handleRating = (rate) => {
        setRating(rate);
    };

    const [selectedMethod, setSelectedMethod] = useState("Wallet (Treats 100)");
    const handleChange = (event) => {
        setSelectedMethod(event.target.value);
    };
    //carousls functionalaity
    const items = [
        {
            image: "/frisbeeImage/store_process_carouslos.png",
            discount: "50% Discount",
            description: "Wallet (Treats 100)",
        },
        {
            image: "/frisbeeImage/store_process_carouslos.png",
            discount: "50% Discount",
            discount: "30% Discount",
            description: "Cash Payment",
        },
        {
            image: "/frisbeeImage/store_process_carouslos.png",
            discount: "50% Discount",
            discount: "20% Discount",
            description: "Apple Pay",
        },
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    const store_process = () => {
        navigate("/boardingcheckoutdetail");
    };

    return (
        <>
            <Header></Header>
            <div className="container my-4 mt-5">
                <div className="row">
                    <div className="col-md-8">
                        <ol className="steps_store_personal_deatils">
                            <li className="step1 current progress_bar_first_step">
                                <span className="progress_bar_first_step_span">
                                    Personal Details
                                </span>
                            </li>
                            <li className="step1 current progress_bar_first_step_second_details_page">
                                <span className="progress_bar_first_step_second_span_details details_details">
                                    Checkout
                                </span>
                            </li>
                            <li className="step1 current progress_bar_first_step_third">
                                <span className="progress_bar_first_step_third_span">
                                    Payment
                                </span>
                            </li>
                        </ol>


                        <div className="">
                            <div className="pet_details_info mt-4">
                                <p>Service Details</p>
                                <div id="booking_info">
                                <div className="d-flex grooming_box" >
                                    <div>
                                        <img src={testimonial_img} alt="testinominal_img"></img>
                                        <div className="add_review  my-0" id="add_review">
                                            <ul className="review_pet">

                                                <li><FaStar /></li>
                                                <li><FaRegStarHalfStroke /></li>

                                            </ul>
                                            <span>4.2</span>
                                        </div>
                                    </div>
                                    <div className="personal_details_petinfo">
                                        <h2>Basic Grooming... </h2>
                                        <p>(Without Haircut/Shave)...</p>
                                        <h4>AED 50.00</h4>

                                    </div>
                                </div>

                                <div className="d-flex pet_details_info mx-2 justify-content">
                                    <div>
                                    <img src={testimonial_img} alt="testinominal_img" className="testnominal_img mx-0"></img>
                                    </div>
                                    <div className="personal_details_petinfo">
                                        <h2>Pet Avenue</h2>
                                        <p>Dubai Al Warsan 3, Birds and Pets Market</p>
                                    </div>
                                    <div className="add_review">
                 <ul className="review_pet">
                     <li><FaStar /></li>
                     <li><FaStar /></li>
                     <li><FaStar /></li>
                     <li><FaStar /></li>
                     <li><FaRegStarHalfStroke /></li>
                    
                 </ul>
                 <span>4.2</span>
             </div>
                                </div>

                                <div className="d-flex pet_details_info mx-2 justify-content">
                                    <div className="pick_drop_info">
                                          <p className="pet_info">
                                            <span>Type :</span> 
                                          Pick up & Drop</p>
                                    </div>
                                    <div  className="pick_drop_info">
                                    <p  className="pet_info">
                                        <span>Date :</span> 
                                        12/02/2024</p>
                                    </div>
                                      <div  className="pick_drop_info">
                                      <p  className="pet_info">
                                        <span> Time : </span> 
                                        9:00 AM</p>
                                      </div>
                                </div>
                                </div>
                              
                            </div>
                            <div className="pet_details_info mt-4">
                                <p>Pet Details</p>
                                <div className="more_info">
                                    <div className="d-flex">
                                        <div className="py-0">
                                            <img src={testimonial_img} alt="testinominal_img" className="pet_img"></img>
                                        </div>
                                        <div className="py-0">
                                            <h2 className="pet_details_name">Skudo</h2>
                                            <p className="pet_info"><span>Age : </span>1 year 8 months</p>
                                            <p className="pet_info"><span>Gender : </span>Male</p>
                                        </div>
                                        <div className="py-0">
                                            <p className="pet_info pt-3"><span>Breed :</span>Mixed</p>
                                            <p className="pet_info"><span> Location :</span>Dubai</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="pet_details_info mt-4">
                                <p>Pet Owner Details</p>
                                <div className="more_info">
                                    <div>
                                        <h1>Skudo</h1>
                                        <ul>
                                            <li><span><MdCall /></span><p>+971-XX-1234567</p></li>
                                            <li><span><FaEnvelope></FaEnvelope></span><p>dineatadm@address.com</p></li>
                                            <li><span><FaLocationDot /></span><p>C-25 MiQB Building , Sector 58, Noida, Uttar Pradesh 201309</p></li>
                                        </ul>
                                    </div>

                                </div>
                            </div>


                            <div className="pet_details_info mt-4">
                                <p>Pick Up & Drop Address</p>
                                <div className="more_info p-3 mb-0" id="booking_info" >
                                    <p className="mb-0 address_details">Rufus, +971-XX-1234567, dineatadm@address.com
                                    C-20/1, C Block, Phase 2, Industrial Area, Sector 62, Noida, Uttar Pradesh 201301</p>

                                </div>
                            </div>
                        </div>
                        <div className="special_notes_container">
                            <div className="alert alert-primary custom_alert" role="alert">
                                You will earn 4.00 frisbee treats in this order!
                            </div>
                            <div className="form-group store_process_form_section">
                                <form action="#">
                                    <label htmlFor="specialNotes">Special Notes</label>
                                    <input
                                        type="text"
                                        className="form-control custom_input"
                                        id="specialNotes"
                                        placeholder="Add notes here...."
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="store_process_card_name">
                            <span className="font-weight-bold">Payment Details</span>
                        </div>
                        <div className=" mt-3">
                            <div className="store_process_payement_deatils_heading">
                                <h6>Select Payment Method</h6>
                            </div>
                            <div className="payment-methods p-3 store_process_wallet_section">
                                {[
                                    "Wallet (Treats 100)",
                                    "Cash Payment",
                                    "Apple Pay",
                                    "Debit / Credit Card",
                                ].map((method, index) => (
                                    <div
                                        className="form-check store_process_radio_section"
                                        key={index}
                                    >
                                        <input
                                            className="form-check-input custom-radio"
                                            type="radio"
                                            name="paymentMethod"
                                            id={`paymentMethod ${index}`}
                                            value={method}
                                            checked={selectedMethod === method}
                                            onChange={handleChange}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor={`paymentMethod ${index}`}
                                        >
                                            {method}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {/* //// */}
                        <div className="store_process_apply_coupon">
                            <h5>Apply Coupons</h5>
                        </div>
                        <div className="form-group store_process_right_input">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Coupon/Discount code"
                            // style={{ borderRadius: "25px", padding: "10px 20px" }}
                            />
                        </div>
                        {/* //Carousles */}
                        <div className="store_process_payement_carousls">
                            <Slider
                                {...settings}
                                className="store_process_payement_carousls_child_div"
                            >
                                {items.map((item, index) => (
                                    <div key={index} className="discount_slide">
                                        <div className="d-flex justify-content-center align-items-center store_process_carousls_image">
                                            <img
                                                className="d-block"
                                                src={item.image}
                                                alt={item.description}
                                                style={{
                                                    width: "75px",
                                                    height: "75px",
                                                    borderRadius: "10px",
                                                }}
                                            />
                                            <div className="discount_details_store_process">
                                                <h5 className="text-danger">{item.discount}</h5>
                                                <p>{item.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                        <div className="payment-details store_process_payement_deatils_heading mt-2">
                            <h6>Payment Details</h6>
                            <div className="store_process_payment_details_heading_child_div">
                                <table className="table  bg-light">
                                    <tbody>
                                        <tr>
                                            <td>Detail</td>
                                            <td className="text-end">Price</td>
                                        </tr>
                                        <tr>
                                            <td>Subtotal</td>
                                            <td className="text-end">50 AED</td>
                                        </tr>
                                        <tr>
                                            <td>Tier Discount</td>
                                            <td className="text-end text-danger">-AED 0.86</td>
                                        </tr>
                                        <tr>
                                            <td>Total</td>
                                            <td className="text-end">AED 49.14</td>
                                        </tr>
                                        <tr>
                                            <td>Treats Used (10)</td>
                                            <td className="text-end">-1</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="row mb-4 mt-4 font-weight-bold store_process_payement_deatils_heading_child_div store_process_deatils_price">
                                <div className="col">Amount Due</div>
                                <div className="col text-end">AED 39.14</div>
                            </div>
                            <div className="login_user_otp_for_bottom_button" >

                                <button className="btn btn-danger btn-lg mb-3 user_otp_first" onClick={store_process}>
                                    Complete Order
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}

export default Boarding_Pickup_checkout;
