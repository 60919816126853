import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./Home/Home";
// import Login from "./components/Login/login";
import Login from './components/login/login'
import LoginUserOtp from "./components/loginUserOtp/loginUserOtp";
import CreatePetProfile from "./components/CreatePetProfile/createPetProfile";
import CreatePetProfileSecondOne from "./components/CreatePetProfile/createPetProfileSecondOne";
import CreatePetParentName from "./components/CreatePetProfile/createPetParentName";
import CreateEnterWonerDetiail from "./components/CreatePetProfile/createEnterOwnerDetiail";
import CreateVerifyOwnerDeatils from "./components/CreatePetProfile/createVerifyOwnerDeatils";
import StoreProcess from "./components/StoreProcess/storeProcess";
import StorePersonalDeatils from "./components/StoreProcess/storePersonalDeatils";
import StoreProcessFinalDetils from "./components/StoreProcess/storeProcessFinalDetils";
// import LoginNew from "./components/Login/loginnew";
import LoginNew from "../src/components/login/loginnew"
import NewLoginUserOtp from "./components/loginUserOtp/newLoginUserOtp";
import StoreProcessItemDeatils from "./components/StoreProcess/storeProcessItemDeatils";
import DeliveryProcessFinalDetails from "./components/deliveryProcessStore/deliveryProcessFinalDetails";
import DeliveryProcessItemDeatils from "./components/deliveryProcessStore/deliveryProcessItemDeatils";
import DeliveryStorePopup from "./components/deliveryProcessStore/deliveryStorePopup";
import Grooming_listing from "./Grooming_listing/Grooming_listing";
import Petlising_MainPage from "./Pet_Listing/Petlisting_MainPage";
import Adoption_MainPage from "./AdoptionList/Adoption_MainPage";
import Adoption_detail from "./AdoptionList/Adoption_detail";
import Adoptiondog_detail from "./AdoptionList/Adoptiondog_detail";
import Petstore_friendlydetails from "./Pet_Listing/Petstore_friendlydetails";
import Grooming_storedetails from "./Grooming_listing/Grooming_storedetails";
import Pet_Shop from "../src/PetShop/Pet_Shop";
import Shop_productlisting from "./PetShop/Shop_productlisting";
import Productdetail from "./PetShop/Productdetail";
import Deliverypersonaldetail from "./components/deliveryProcessStore/deliverypersonaldetail";
import Addnew_address from "./components/deliveryProcessStore/addnew_address";
import Grooming_personaldetail from "./Grooming_listing/StoreModule/GroomingPersonal_details";
import Checkoutdetail from "./Grooming_listing/StoreModule/chackout_detail";
import Personal_detail from "./Grooming_listing/Pick & Drop/Personal_detail";
import Pickup_checkout from "./Grooming_listing/Pick & Drop/Pickup_checkout";
import Devlivery_process from "./components/deliveryProcessStore/devlivery_process";


// Day care listing pages
import Day_Care_listing from "./Day_Care_Listing/Day_Care_listing";
import Day_care_personal_details from "./Day_Care_Listing/StoreModule/day_care_personal_details";
import Day_Care_Store_Details from "./Day_Care_Listing/Day_Care_Store_Details";
import Day_care_chackout_detail from "./Day_Care_Listing/StoreModule/Day_care_chackout_detail";
import Day_Personal_detail from "./Day_Care_Listing/Pick & Drop/Day_Personal_detail";
import Day_Pickup_checkout from "./Day_Care_Listing/Pick & Drop/Day_Pickup_checkout";

//Boarding listing pages
import Boarding_listing from "./Boarding_listing/Boarding_listing";
import BoardingPersonal_details from "./Boarding_listing/StoreModule/BoardingPersonal_details";
import Boarding_storedetails from "./Boarding_listing/Boarding_storedetails";
import BoardingCheckoutdetail from "./Boarding_listing/StoreModule/boarding_detail";
import Boarding_Personal_detail from "./Boarding_listing/Pick & Drop/Boarding_Personal_detail";
import Boarding_Pickup_checkout from "./Boarding_listing/Pick & Drop/Boarding_Pickup_checkout";

//Verterniary listing pages
import Verterinary_listing from "./Verterinary_listing/Verterinary_listing";
import VerterinaryPersonal_details from "./Verterinary_listing/StoreModule/VerterinaryPersonal_details";
import Verterniary_storedetails from "./Verterinary_listing/Verterniary_storedetails";
import Verterniarychackout_detail from "./Verterinary_listing/StoreModule/Verterniarychackout_detail";
import Verterniary_Personal_detail from "./Verterinary_listing/Pick & Drop/Verterniary_Personal_detail";
import Verterniary_Pickup_checkout from "./Verterinary_listing/Pick & Drop/Verterniary_Pickup_checkout";


//Training Listing pages
import Training_listing from "./Training_List/Training_listing";
import TrainingPersonal_details from "./Training_List/StoreModule/TrainingPersonal_details";
import Training_storedetails from "./Training_List/Training_storedetails";
import Traningchackout_detail from "./Training_List/StoreModule/Traningchackout_detail";
import Traning_Personal_detail from "./Training_List/Pick & Drop/Traning_Personal_detail";
import Training_Pickup_checkout from "./Training_List/Pick & Drop/Training_Pickup_checkout";


//Pet Shop Listing pages
import Pet_Shop_listing from "./Pet_Shop_Listing/Pet_Shop_listing";
import PetShopPersonal_details from "./Pet_Shop_Listing/StoreModule/PetShopPersonal_details";
import PetShop_storedetails from "./Pet_Shop_Listing/PetShop_storedetails";
import PetShop_chackout_detail from "./Pet_Shop_Listing/StoreModule/PetShop_chackout_detail";
import Pet_Shop_Personal_detail from "./Pet_Shop_Listing/Pick & Drop/Pet_Shop_Personal_detail";
import Pet_Shop_Pickup_checkout from "./Pet_Shop_Listing/Pick & Drop/Pet_Shop_Pickup_checkout";



//pet shop sub categroies routing here
import Clothing_Pet_Shop from "./PetShopSubCategroies/Clothing_Bedding/Clothing_Pet_Shop";
import Food_categories from "./PetShopSubCategroies/Food/Food_categories";
import Toys_categories from "./PetShopSubCategroies/Toys/Toys_categories";
import Treat_categories from "./PetShopSubCategroies/Treat_categroies/Treat_categories";
import Walk_categories from "./PetShopSubCategroies/Walk Essentials/Walk_categories";
import Grooming_categories from "./PetShopSubCategroies/Grooming_categories/Grooming_categories";
import Health_categories from "./PetShopSubCategroies/Healthcare/Health_categories";
import Accessories_categories from "./PetShopSubCategroies/Accessories/Accessories_categories";
const App = () => {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        {/* Grooming Service pages */}
        <Route path="/groominglisting" element={<Grooming_listing />}></Route>
        <Route path="/grooming-personaldetail" element={<Grooming_personaldetail />}></Route>
        <Route path="/grooming-storedetails" element={<Grooming_storedetails />}></Route>
        <Route path="/grooming-checkoutdetail" element={<Checkoutdetail />}></Route>
        <Route path="/personal-detail" element={<Personal_detail />}></Route>
        <Route path="/pickup-checkout" element={<Pickup_checkout></Pickup_checkout>}></Route>
        {/* Grooming end pages */}
        {/* Day Care pages */}
        <Route path="/daycarelisting" element={<Day_Care_listing />}></Route>
        <Route path="/daycarepersonaldetails" element={<Day_care_personal_details />}></Route>
        <Route path="/daycarestoredetails" element={<Day_Care_Store_Details />}></Route>
        <Route path="/daycarechackoutdetail" element={<Day_care_chackout_detail />}></Route>
        <Route path="/daypersonaldetail" element={<Day_Personal_detail />}></Route>
        <Route path="/daypickupcheckout" element={<Day_Pickup_checkout></Day_Pickup_checkout>}></Route>
        {/* Boarding pages */}
        <Route path="/boardinglisting" element={<Boarding_listing />}></Route>
        <Route path="/boardingpersonaldetails" element={<BoardingPersonal_details />}></Route>
        <Route path="/boardingstoredetails" element={<Boarding_storedetails />}></Route>
        <Route path="/boardingcheckoutdetail" element={<BoardingCheckoutdetail />}></Route>
        <Route path="/boardingdetail" element={<Boarding_Personal_detail />}></Route>
        <Route path="/boardingpickupcheckout" element={<Boarding_Pickup_checkout></Boarding_Pickup_checkout>}></Route>
        {/* Boarding End pages */}
        {/* Verterinary Service pages */}
        <Route path="/verterinarylisting" element={<Verterinary_listing />}></Route>
        <Route path="/verterinarypersonal_details" element={<VerterinaryPersonal_details />}></Route>
        <Route path="/verterniary_storedetails" element={<Verterniary_storedetails />}></Route>
        <Route path="/verterniarychackout_detail" element={<Verterniarychackout_detail />}></Route>
        <Route path="/verterniary_personal_detail" element={<Verterniary_Personal_detail />}></Route>
        <Route path="/verterniary_Pickup_checkout" element={<Verterniary_Pickup_checkout></Verterniary_Pickup_checkout>}></Route>
        {/* Verterinary end pages */}
        {/* Training Listing pages */}
        <Route path="/training_listing" element={<Training_listing />}></Route>
        <Route path="/TrainingPersonal_details" element={<TrainingPersonal_details />}></Route>
        <Route path="/training_storedetails" element={<Training_storedetails />}></Route>
        <Route path="/traningchackout_detail" element={<Traningchackout_detail />}></Route>
        <Route path="/traning_Personal_detail" element={<Traning_Personal_detail />}></Route>
        <Route path="/training_Pickup_checkout" element={<Training_Pickup_checkout></Training_Pickup_checkout>}></Route>
        {/* Training Listing end pages */}
        {/* Pet Shop Listing pages */}
        <Route path="/petshoplisting" element={< Pet_Shop_listing />}></Route>
        <Route path="/petshoppersonal_details" element={<PetShopPersonal_details />}></Route>
        <Route path="/petshop_storedetails" element={<PetShop_storedetails />}></Route>
        <Route path="/petshop_chackout_detail" element={<PetShop_chackout_detail />}></Route>
        <Route path="/pet_shop_personal_detail" element={<Pet_Shop_Personal_detail />}></Route>
        <Route path="/pet_shop_pickup_checkout" element={<Pet_Shop_Pickup_checkout></Pet_Shop_Pickup_checkout>}></Route>
        {/* Pet Shop  end pages */}


        {/* Pet Shop sub categories pages */}
        <Route path="/clothing_pet_shop" element={< Clothing_Pet_Shop />}></Route>
        <Route path="/food_categories" element={<Food_categories />}></Route>
        <Route path="/toys_categories" element={<Toys_categories />}></Route>
        <Route path="/treat_categories" element={<Treat_categories />}></Route>
        <Route path="/walk_categories" element={<Walk_categories />}></Route>
        <Route path="/grooming_categories" element={<Grooming_categories></Grooming_categories>}></Route>
        <Route path="/health_categories" element={<Health_categories />}></Route>
        <Route path="/accessories_categories" element={<Accessories_categories />}></Route>
        {/* Pet Shop sub categories end pages */}
        {/* adoption pages */}
        <Route path="/adoption-mainpage" element={<Adoption_MainPage />}></Route>
        <Route path="/adoption-detail" element={<Adoption_detail />}></Route>
        <Route path="/adoptiondog-detail" element={<Adoptiondog_detail />}></Route>

        {/* Pet Frieendly pages */}

        <Route path="/petstore-friendlydetails" element={<Petstore_friendlydetails></Petstore_friendlydetails>}></Route>
        <Route path="/petlisting" element={<Petlising_MainPage></Petlising_MainPage>} />

        {/* Pet Shop pages */}
        <Route path="/addnew-address" element={<Addnew_address />}></Route>
        <Route path="/petshop" element={<Pet_Shop />}></Route>
        <Route path="/productdetail" element={<Productdetail />}></Route>
        <Route path="/shop-productlisting" element={<Shop_productlisting></Shop_productlisting>}></Route>

        <Route path="/login" element={<Login />} />
        <Route path="/login-user-otp" element={<LoginUserOtp />} />
        <Route path="/create-pet-profile" element={<CreatePetProfile />} />
        <Route path="/create-pet-profile-second-one" element={<CreatePetProfileSecondOne />} />
        <Route path="/create-pet-parent-name" element={<CreatePetParentName />} />
        <Route path="/create-enter-woner-detiail" element={<CreateEnterWonerDetiail />} />
        <Route path="/create-verify-owner-deatils" element={<CreateVerifyOwnerDeatils />} />
        <Route path="/store-process" element={<StoreProcess />} />
        <Route path="/store-personal-deatils" element={<StorePersonalDeatils />} />
        <Route path="/store-process-final-detils" element={<StoreProcessFinalDetils />} />
        <Route path="/store-process-item-deatils" element={<StoreProcessItemDeatils />} />
        <Route path="/login-new" element={<LoginNew />} />
        <Route path="/new-login-user-otp" element={<NewLoginUserOtp />} />
        <Route path="/delivery-process-final-details" element={<DeliveryProcessFinalDetails />} />
        <Route path="/delivery-process-item-deatils" element={<DeliveryProcessItemDeatils />} />
        <Route path="/delivery-store-popup" element={<DeliveryStorePopup />} />
        <Route path="/devlivery_process" element={<Devlivery_process></Devlivery_process>}></Route>
        <Route path="/deliverypersonaldetails" element={<Deliverypersonaldetail></Deliverypersonaldetail>}></Route>
      </Routes>
    </div>
  );
};

export default App;
