import React, { useState, useRef } from "react";
import { FaUser, FaPhone, FaEnvelope } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import Header from "../../components/Header";
import { FaRegStarHalfStroke } from "react-icons/fa6";
import { testimonial_img } from "../../components/Images";
import { FaStar } from "react-icons/fa";
import { GoPlus } from "react-icons/go";
import { MdCall } from "react-icons/md";
import Pet_infoslider from "../StoreModule/pet_infoslider";
import Footer from "../../components/Footer";
import { Link, useNavigate } from "react-router-dom";

function Traning_Personal_detail() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [show_petdetail, setshow_petdetail] = useState(false);
  const [add_newaddress, setadd_newaddress] = useState(false);
  const handleOpenModal = () => setIsOpen(true);
  const handleCloseModal = () => setIsOpen(false);


  const store_process = () => {
    navigate("/training_Pickup_checkout");
  };
  const add_morehandle = () => {
    setshow_petdetail(true);
  }
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };
  const add_newaddreesheandle = () => {
    setadd_newaddress(true);
  }
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log("Selected file:", file);
  };

  return (
    <>
      <Header></Header>
      <div className="container my-4 mt-5">
        <div className="row">
          <div className="col-md-8">
            <ol className="steps_store_personal_deatils">
              <li className="step1 current progress_bar_first_step">
                <span className="progress_bar_first_step_span">
                  Personal Details
                </span>
              </li>
              <li className="step1 current progress_bar_first_step_second">
                <span className="progress_bar_first_step_second_span">
                  Checkout
                </span>
              </li>
              <li className="step1 current progress_bar_first_step_third">
                <span className="progress_bar_first_step_third_span">
                  Payment
                </span>
              </li>
            </ol>
            <div className="pet_details_info">
              <div className="add_new_info">
                <p>Pet Details</p>
                <button className="add_new_pet" onClick={add_morehandle}>Add New Pet</button>
              </div>
              <div className="pet_slides">
                <Pet_infoslider></Pet_infoslider>
              </div>
            </div>
            {show_petdetail &&
              <div className="create_pet_profile_main_section mt-4">
                <div className="row cretae_pet_profile_form_div_sec">
                  <div className="col-lg-9">
                    <h4>Add Pets Details</h4>
                    <form>
                      <div className="row mb-3 ">
                        <div className="col-md-6 mb-3 mb-md-0 create_pet_for_profile_input">
                          <input
                            type="text"
                            className="form-control store_input_field_field_for_Create_pet"
                            placeholder="Pet Name"
                          />
                        </div>
                        <div className="col-md-6 create_pet_for_profile_input">
                          <input
                            type="text"
                            className="form-control store_input_field_field_for_Create_pet"
                            placeholder="Pet Age"
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-6 mb-3 mb-md-0 create_pet_for_profile_input">
                          <input
                            type="text"
                            className="form-control store_input_field_field_for_Create_pet"
                            placeholder="Breed"
                          />
                        </div>
                        <div className="col-md-6 create_pet_for_profile_input ">
                          <select className="form-control store_input_field_field_for_Create_pet create_pet_for_gender_in_login">
                            <option>Gender</option>
                            <option>Male</option>
                            <option>Female</option>
                          </select>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-12 create_pet_for_profile_input">
                          <input
                            type="text"
                            className="form-control store_input_field_field_for_Create_pet"
                            placeholder="Location"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-lg-3">
                    <div className="upload_container d-flex justify-content-center align-items-center">
                      {/* <div className="upload_box d-flex flex-column justify-content-center align-items-center">
                          <div className="upload_icon">
                            <GoPlus />
                          </div>
                          <div className="upload_text ">
                            Upload <br />
                            Pet Picture
                          </div>
                        </div> */}
                      <div
                        className="upload_box d-flex flex-column justify-content-center align-items-center"
                        onClick={handleClick}
                      >
                        <input
                          type="file"
                          ref={fileInputRef}
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                        />
                        <div className="upload_icon">
                          <GoPlus className="upload_icon" />
                        </div>
                        <div className="upload_text">
                          Upload <br />
                          Pet Picture
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            <div className="pet_details_info mt-4">
              <p>Pet Owner Details</p>
              <div className="more_info">
                <div>
                  <h1>Skudo</h1>
                  <ul>
                    <li><span><MdCall /></span><p>+971-XX-1234567</p></li>
                    <li><span><FaEnvelope></FaEnvelope></span><p>dineatadm@address.com</p></li>
                    <li><span><FaLocationDot /></span><p>C-25 MiQB Building , Sector 58, Noida, Uttar Pradesh 201309</p></li>
                  </ul>
                </div>

              </div>
            </div>
            <div className="store_process_payement_deatils_heading mt-4">
              <div className="d-flex justify-content">
                <div>
                  <h6 className="mb-0 mt-2">Select Pick up & drop Address</h6>
                </div>
                <div>
                  <button className="add_new_address mb-0" onClick={add_newaddreesheandle}>Add A New Address</button>

                </div>
              </div>

              <hr></hr>
            </div>

            {add_newaddress &&
              <div className="addnew_address my-4">
                <form>
                  <div className="row">
                    <div className="col-md-4">
                      <input type="text" placeholder="First Name" className="form-field"></input>
                    </div>
                    <div className="col-md-4">
                      <input type="text" placeholder="Last Name" className="form-field"></input>
                    </div>
                    <div className="col-md-4">
                      <input type="tel" placeholder="First Name" className="form-field"></input>
                    </div>
                    <div className="col-md-8">
                      <input type="email" placeholder="Email Address" className="form-field"></input>
                    </div>
                    <div className="col-md-4" >
                      <select className="form-field">
                        <option>city</option>
                        <option>Bomaby</option>

                      </select>
                    </div>
                    <div className="col-md-12">
                      <input type="text" placeholder="Address Line 1" className="form-field"></input>
                    </div>
                    <div className="col-md-12">
                      <input type="text" placeholder="Building Name" className="form-field"></input>
                    </div>
                    <div className="col-md-12">
                      <input type="text" placeholder="Apartment / Villa Name" className="form-field"></input>
                    </div>
                    <div className="col-md-12">
                      <div className="save_btn">
                        <input type="radio"></input>
                        <p className="save_info">Save your information for future checkouts.</p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            }
            <div className="addnew_address mb-3">
              <div>
                <input type="radio"></input>
                <span>Address 01</span>
              </div>

              <p>Rufus, +971-XX-1234567, dineatadm@address.com
                C-20/1, C Block, Phase 2, Industrial Area, Sector 62, Noida, Uttar Pradesh 201301</p>
            </div>

            <div className="addnew_address mb-3">
              <div>
                <input type="radio"></input>
                <span>Address 02</span>
              </div>

              <p>Rufus, +971-XX-1234567, dineatadm@address.com
                C-20/1, C Block, Phase 2, Industrial Area, Sector 62, Noida, Uttar Pradesh 201301</p>
            </div>
          </div>
          <div className="col-md-4">
            <div class="store_process_card_name"><span class="font-weight-bold">Selected Service</span></div>
            <div className="grooming_box">
              <div className="d-flex">
                <div>
                  <img src={testimonial_img} alt="testinominal_img"></img>
                  <p></p>
                </div>
                <div className="personal_details_petinfo">
                  <h2>Basic Grooming... </h2>
                  <p>(Without Haircut/Shave)...</p>
                  <h4>AED 50.00</h4>

                </div>
              </div>
              <div className="pet_details_info mt-0">
                <h2>Booking Details</h2>
                <ul className="date_time">
                  <li><p>Date :</p> <span>12/02/2024</span></li>
                  <li><p>Time :  </p> <span>9:00 AM</span></li>
                </ul>
              </div>

              <div className="pet_details_info">
                <div>
                  <div className="d-flex">
                    <div>
                      <div className="add_review mx-0">
                        <ul className="review_pet">
                          <li><FaStar /></li>
                          <li><FaStar /></li>
                          <li><FaStar /></li>
                          <li><FaStar /></li>
                          <li><FaRegStarHalfStroke /></li>

                        </ul>
                        <span>4.2</span>
                      </div>
                      <h2 className="pet_name">Pet Avenue</h2>
                    </div>
                    <div>
                      <img src={testimonial_img} alt="" className="testnominal_img mx-0" />
                    </div>
                  </div>
                  <div className="d-flex mt-3">
                    <span className="location_icon"><FaLocationDot /></span>
                    <p className="location_view">Dubai Al Warsan 3, Birds and Pets Market</p>
                  </div>

                </div>
              </div>
              <div>

              </div>

            </div>
            <div className="login_user_otp_for_bottom_button mt-4">
              <button className="btn btn-danger btn-lg mb-3 user_otp_first" onClick={store_process}>
                Proceed to Order
              </button>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </>
  );
}

export default Traning_Personal_detail;
