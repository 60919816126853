export const logo = "../frisbeeImage/logo.png";
/* home main slider img*/
export const left_side = "../frisbeeImage/left_side.png";
export const mainslider = "../frisbeeImage/main_banner.png";
/* services home pags slider images*/
export const service_one = "../frisbeeImage/service_one.svg";
export const service_two = "../frisbeeImage/service_two.svg";
export const service_third = "../frisbeeImage/service_third.svg";
export const service_fourth = "../frisbeeImage/service_fourth.svg";
export const service_sixth = "../frisbeeImage/service_sixth.svg";
export const service_seventh = "../frisbeeImage/service_seventh.svg";
export const service_fifth = "../frisbeeImage/service_fifth.svg";
export const service_eigth = "../frisbeeImage/service_eigth.svg";





/* Home Page Image Grooimg Slider*/
export const grooimg_firstimg = "../frisbeeImage/grooming_first_img.jpeg";
export const grooimg_secondimg = "../frisbeeImage/grooming_second.jpeg";
export const grooimg_thirdtimg = "../frisbeeImage/grroming_third.jpeg";
export const grooimg_fourthimg = "../frisbeeImage/grroming_fourth.png";
export const grooming_storeimg = "../frisbeeImage/grroming_third.jpeg";


export const mini_serviceone = "../frisbeeImage/toys.svg";
export const miniservice_sec = "../frisbeeImage/food.svg";
export const miniservice_thri = "../frisbeeImage/treats_biscuits.svg";
export const miniservice_fourth = "../frisbeeImage/miniservice_fourth.png";
export const clothing = "../frisbeeImage/clothing.svg";
export const  healthcar = "../frisbeeImage/healthcare.svg"
export const grooming_hygeine = "../frisbeeImage/grooming_hygeine.svg";
export const accessories = "../frisbeeImage/accessories.svg";

/*  Home Page enqyuiery img*/
export const inquery = "../frisbeeImage/enquiry_banner.jpeg";

/*  Home Page Shop img*/
export const adoptbanner  = "../frisbeeImage/get_forever.png";
/*  Home Page foot print app logo imgs in home page*/

export const leftfootprint = "../frisbeeImage/left_foot_print.svg";
export const rightfootprint = "../frisbeeImage/right_foot_print.svg";
export const leftimg = "../frisbeeImage/left img.svg";
export const footerlogoes =  "../frisbeeImage/applogoes.png";

/* Home Page Latest News*/
export const Latestnews=  "../frisbeeImage/latestnews.png";

/*  Home Page testimonial*/
export const testimonial_img =  "../frisbeeImage/dog_img.png";

/* grooming listing grooming_banner page*/
export const groomingmain_banner = "../frisbeeImage/Mid Banner.svg";
export const bottom_line =   "../frisbeeImage/bottom_line.svg";
export const anim_img = "../frisbeeImage/anim_img.png";

export const Shopbanner = "./frisbeeImage/shop_banner.png";
/* PeTfriendly Page*/
export const PeTfriendly =  "../frisbeeImage/petfriendly.png";

/* adoption page images*/
export const dog_listone = "../frisbeeImage/dog_listone.jpeg";
export const dog_listsecond = "../frisbeeImage/dog_img.png";
export const doglist_three = "../frisbeeImage/doglist_three.jpeg";
export const dog_listfour = "../frisbeeImage/dog_listfour.png";
export const icon_first = "../frisbeeImage/icon_first.svg";
export const icon_second = "../frisbeeImage/icon_second.svg";
export const icon_third = "../frisbeeImage/icon_third.svg";

/* shop_product details page */
export const product_pic =  "../frisbeeImage/product_pic.png";
export const productsliderfirst = "../frisbeeImage/productslier-one.png";
export const productslidersecond = "../frisbeeImage/produtlsider-two.jpeg";
export const productsliderthird = "../frisbeeImage/productslider-third.png";
export const productsliderfourth = "../frisbeeImage/productslider_fourth.png";