module.exports = {
    API_KEY : "d42a0d190464a2be90977c3996382811",

    // API_ADMIN_URL : "https://api.tryfrisbee.com/admin/",
    // API_BASE_URL : "https://api.tryfrisbee.com/",

    // API_ADMIN_URL : "https://algodev.in:60000/admin/",
    // API_BASE_URL : "https://algodev.in:60000/",
    
    API_ADMIN_URL : "http://localhost:3300/admin/",
    API_BASE_URL : "http://localhost:3300/front/v1/",
    
    ASSETS_BASE_URL : "https://frisbee-llc.s3.me-central-1.amazonaws.com/",
    GOOGLE_MAP_API : 'AIzaSyDeiXX90FyborPREpX0JQZ9G-j0Ooy9-YM'
}