import Header from "../components/Header";
import Footer from "../components/Footer";
import Productslider from "./Productslider";
import { product_pic ,icon_first,icon_second,icon_third} from "../components/Images";
import { FaStar, FaRegStarHalfStroke, FaLocationDot } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Frequentelyslider from "./Frequentelyslider";

const Productdetail = () =>{
    const navigate = useNavigate();

    const handle_NextPage = () => {
      navigate("/store-process"); 
    };
    return(
        <>
        <Header />
        <div className="mt-5 ">
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <Productslider />
                </div>
                <div className="col-md-6" id="product-inner">
                <div className="grooming_board">
            <div className="grooming_board_productcard">
              <div className="add_review">
                <ul>
                  <li><FaStar /></li>
                  <li><FaStar /></li>
                  <li><FaStar /></li>
                  <li><FaStar /></li>
                  <li><FaRegStarHalfStroke /></li>

                </ul>
                <span>4.2</span>
              </div>
              <h1>Pedigree Beef Chunks in Gravy Wet Dog Food</h1>
              <div className="product_size">
                <p className="gram_productsize">500 g</p>
                <p className="kilo_productsize">1kg</p>
              </div>
              <div className="product_price">
                <p>AED 500</p>
                <strike>AED65.50</strike>
              </div>
              <button onClick={handle_NextPage}>Add To Cart</button>
              <div className="">
              <ul className="dogdetails_info">
                            <li>
                               
                                <div className="doginfo_dtailsinnersection">
                                <h1> Delivery Options</h1>
                                <span className="option_cart">Options at cart</span>
                                </div>
                               
                            </li>
                            <li>
                            <div>
                                <img src={icon_second}/>
                                </div>
                                <div className="doginfo_dtailsinnersection">
                                <p> Delivery 
                               </p> 
                                <h1>  Home Delivery</h1>
                                </div>
                            </li>
                            <li>
                            <div>
                                <img src={icon_third}/>
                            </div>
                            <div className="doginfo_dtailsinnersection">
                                <p> In-Store </p> 
                                <h1> Click & Collect</h1>
                                </div>
                            </li>
                          </ul>
                          <p className="description_details">Description</p>
                          <p >It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
              </div>
            </div>
          </div>
                </div>
            </div>
        </div>

        <div className="frequenelty  my-4">
            <div className="container">
                <div className="row">
                <div className="col-md-12 grooming_info">
                    <div className="features_grooming">
                            <h1 className="main_heading"> <span>Frequently Bought </span>Together</h1>
                            <p className="sub_heading">It is a long established fact that a reader will be distracted by the readable content</p> 
                    </div>
                    </div>
                </div>
            </div>
            <div className="frequentlyslider mt-3">
            <div className="row">
                <div className="col-md-12">
                    <Frequentelyslider/>
                </div>
            </div>
            </div>
           
        </div>
        </div>
     
        <Footer />
        </>
    )
}
export default Productdetail;