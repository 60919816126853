import {adoptbanner} from "../components/Images";
import { Link } from "react-router-dom";
import Adoption_MainPage from "../AdoptionList/Adoption_MainPage";
const Adoptbanners = () =>{
    return(
        <>
         <div className="adopt_banner">
            <img src={adoptbanner} />
            <div className="adoptbanner_innnercontent">
                <h1>Give A Home,<br></br>
                Get A Forever Friend</h1>
                <button><Link to="/adoption-mainpage">Adopt Now!</Link></button>
            </div>
         </div>
        </>
    )
}
export  default Adoptbanners;