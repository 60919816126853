import Header from "../components/Header";
import Footer from "../components/Footer";
import { FaStar, FaRegStarHalfStroke, FaLocationDot, FaWhatsapp, FaPhone } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { dog_listone, dog_listsecond, doglist_three, dog_listfour, icon_first, icon_second, icon_third } from "../components/Images";
import { GoDotFill } from "react-icons/go";
import Appdownaload from "../Home/Appdowanload";
import Petslider from "./Petslider";
const Petstore_friendlydetails = () => {
    return (
        <>
            <Header></Header>
            <div className="adopt_dogdetails">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="">

                                <div className="grooming_board_cardbox">
                                    <div className="offer_discount">
                                        <h1>20%</h1> <p>off</p>
                                    </div>
                                    <img src={dog_listfour} style={{height: '327'}}/>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="">
                                <div className="add_review">
                                    <ul>
                                        <li><FaStar /></li>
                                        <li><FaStar /></li>
                                        <li><FaStar /></li>
                                        <li><FaStar /></li>
                                        <li><FaRegStarHalfStroke /></li>

                                    </ul>
                                    <span>4.2</span>
                                </div>
                                <div className="adoptionstore_details">

                                    <div>
                                        <h1>The Pet’s Cafe</h1>
                                        <p> <FaLocationDot id="location" /> Dubai Al Warsan 3, Birds and Pets Market</p>
                                        <ul className="connect_btn mt-2">
                                            <button className="whatsapp_icon"><Link to="/"><FaWhatsapp /> Connect On Whatsapp</Link></button>
                                            <button className="call"><Link to="/"><FaPhone /> Connect On Call</Link></button>
                                        </ul>

                                    </div>
                                </div>
                                <h2 className="galler_heading">About Cafe</h2>
                                <p class="mx-0 mb-0"><span class="shop_info">Working Days : </span> 5 Days (Mon, Tue, Wed, Thr , Fri, Sat )</p><p class="mx-0"><span class="shop_info">Working Hours : </span> 08:00 AM - 18:00 PM</p>
                                <p className="inner_sec mt-1">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>

                                <div >
                                    <h1 className="galler_heading">Cafe Gallery</h1>
                                    <ul className="cafe_gallery">
                                        <li><img src={dog_listone}></img></li>
                                        <li><img src={dog_listsecond}></img></li>
                                        <li><img src={doglist_three}></img></li>
                                        <li><img src={dog_listfour}></img></li>
                                        <li><img src={doglist_three}></img></li>
                                        <li><img src={dog_listone}></img></li>
                                        <li><img src={dog_listsecond}></img></li>
                                        <li><img src={dog_listfour}></img></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="app_dowanlad">
                <Appdownaload></Appdownaload>
            </div>


            <div className="container adopt_detailes">
                <div className="row my-3">
                    <div className="col-md-9 grooming_info">
                        <div className="features_grooming ">
                            <h1 className="main_heading" >Our   <span>Dog Friendly</span> List</h1>
                            <p className="sub_heading" >It is a long established fact that a reader will be distracted by the readable content</p>
                        </div>
                    </div>
                    <div className="col-md-3">


                    </div>

                </div>

            </div>
            <div className="pet_slidercontainer">
                <Petslider></Petslider>
            </div>

            <Footer></Footer>
        </>
    )
}
export default Petstore_friendlydetails;