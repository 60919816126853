import "./adoption.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { FaStar, FaRegStarHalfStroke, FaLocationDot} from "react-icons/fa6";
import { dog_listone,dog_listfour,doglist_three } from "../components/Images";
import Latest_news from "../Home/Latest_news";
import Appdownaload from "../Home/Appdowanload";
import { GoDotFill } from "react-icons/go";
import { CiSearch } from "react-icons/ci";
import Adoptiondog_detail from "./Adoptiondog_detail";
const Adoption_detail = () =>{
    return(
        <>
        <Header/>
        <div className="adoption_info">
          <div className="container mt-5">
            <div className="row my-4">
                <div className="col-md-2 px-0">
                <div className="pet_avenue">
                    <img src={dog_listone} />
                    <p>20% OFF</p>

                </div>
                </div>
                <div className="col-md-8">
                <div className="rightside_list">
                    <div className="add_review">
                        <ul>
                            <li><FaStar /></li>
                            <li><FaStar /></li>
                            <li><FaStar /></li>
                            <li><FaStar /></li>
                            <li><FaRegStarHalfStroke /></li>

                        </ul>
                        <span>4.2</span>
                    </div>
                    <h1>Pet Avenue</h1>
                    <p className="mx-0">It is a long established fact that a reader will be distracted by content...</p>
                    <div>
                      
                        <p className="mx-0"> <FaLocationDot id="location" /> Dubai Al Warsan 3, Birds and Pets Market</p>
                        <p className="mx-0"><span className="shop_info">Working Days : </span> 5 Days (Mon, Tue, Wed, Thr , Fri, Sat )</p>
                        <p className="mx-0"><span className="shop_info">Working Hours : </span> 08:00 AM - 18:00 PM</p>
                    </div>
                </div>
                </div>
                <div className="col-md-2 px-0">
                    <button className="textend"><Link to="/">Get Direction</Link></button>
                </div>
                <div className="col-md-12 px-0">
                    <h1>Description</h1>
                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
                </div>
                <div className="col-md-12 px-0">
                    <h1>Centre Facilities:</h1>
                    <ul className="ceter_fac">
                        <li>1. Indoor play area</li>
                        <li>1. Indoor play area</li>
                        <li>1. Indoor play area</li>
                        <li>1. Indoor play area</li>
                    </ul>
                </div>
            </div>
         
          </div>
          
        </div>
        
        <div className="container adopt_detailes">
        <div className="row my-3">
                <div className="col-md-9 grooming_info">
                        <div className="features_grooming ">
                            <h1 className="main_heading" >Our   <span>Dog’s </span> List</h1>
                            <p className="sub_heading" >It is a long established fact that a reader will be distracted by the readable content</p> 
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="view_btn">
                        <input placeholder="Search here....." />
                        <button className="search_btn"><CiSearch /></button>
                       
                        </div>
                    
                    </div>
                   
                </div>
        <div className="row ">
                <div className="col-md-3">
                <div className="grooming_board">
             <Link to="/adoptiondog-detail">
             <div className="grooming_board_cardbox"> 
            
            <img src={dog_listone} />
            </div>
            <div className="">
            <h1 className="location_heading">Skudo</h1>
            <div className="location_creation">
             <p className="time_adoptionpet">1 year 8 months</p>
             <p className="time_adoptionpet">Husky <span><GoDotFill id="dots" /> </span> Male</p>
            </div>
          
          </div>
             </Link>
          
         </div>
                </div>
                <div className="col-md-3">
                <div className="grooming_board">
                <Link to="/Adoptiondog_detail">
             <div className="grooming_board_cardbox"> 
            
            <img src={dog_listfour} />
            </div>
            <div className="">
            <h1 className="location_heading">Skudo</h1>
            <div className="location_creation">
             <p className="time_adoptionpet">1 year 8 months</p>
             <p className="time_adoptionpet">Husky <span><GoDotFill id="dots" /> </span> Male</p>
            </div>
          
          </div>
             </Link>
          
         </div>
                </div>
                <div className="col-md-3">
                <div className="grooming_board">
                <Link to="/Adoptiondog_detail">
             <div className="grooming_board_cardbox"> 
            
            <img src={dog_listone} />
            </div>
            <div className="">
            <h1 className="location_heading">Skudo</h1>
            <div className="location_creation">
             <p className="time_adoptionpet">1 year 8 months</p>
             <p className="time_adoptionpet">Husky <span><GoDotFill id="dots" /> </span> Male</p>
            </div>
          
          </div>
             </Link>
          
         </div>
                </div>
                <div className="col-md-3">
                <div className="grooming_board">
                <Link to="/Adoptiondog_detail">
             <div className="grooming_board_cardbox"> 
            
            <img src={doglist_three} />
            </div>
            <div className="">
            <h1 className="location_heading">Skudo</h1>
            <div className="location_creation">
             <p className="time_adoptionpet">1 year 8 months</p>
             <p className="time_adoptionpet">Husky <span><GoDotFill id="dots" /> </span> Male</p>
            </div>
          
          </div>
             </Link>
          
         </div>
                </div>
            </div>
        </div>
       
        <div className="app_dowanlad">
    <Appdownaload></Appdownaload>
</div>
        <div className="container">
        <div className="row my-3">
                <div className="col-md-10 grooming_info">
                        <div className="features_grooming ">
                            <h1 className="main_heading" >Latest <span>News </span></h1>
                            <p className="sub_heading" >It is a long established fact that a reader will be distracted by the readable content</p> 
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="view_btn">
                        <button  className="view_all new_insights"><Link to="/">View All Insights</Link></button>
                        </div>
                    
                    </div>
                    <div className="col-md-12 my-3">
                     <Latest_news></Latest_news>
                    </div>
                </div>
        </div>
       

        <Footer/>
        </>
    )
}
export default Adoption_detail;