import { FaStar, FaRegStarHalfStroke, FaLocationDot} from "react-icons/fa6";
import { GoDotFill } from "react-icons/go";
import { Link } from "react-router-dom";
import Adoption_detail from "./Adoption_detail";
import { grooimg_firstimg } from "../components/Images";
const Adoption_list = () =>{
    return(
        <>
        <div className="row">
          <div className="col-md-12">
          <div className="avenue_pet">
            <Link to="/adoption-detail">
                <div className="pet_avenue">
                    <img src={grooimg_firstimg} />
                    <p>20% OFF</p>

                </div>
                <div className="rightside_list">
                    <div className="add_review">
                        <ul>
                            <li><FaStar /></li>
                            <li><FaStar /></li>
                            <li><FaStar /></li>
                            <li><FaStar /></li>
                            <li><FaRegStarHalfStroke /></li>

                        </ul>
                        <span>4.2</span>
                    </div>
                    <h1 className="my-0">Pet Avenue</h1>
                    <p className="mx-0">It is a long established fact that a reader will be distracted by content...</p>
                    <div>
                        <p className="mx-0"><span className="shop_info">Dubai</span> <GoDotFill id="dots" />          Open (10:00 AM - 07:00 PM) </p>
                        <p className="mx-0"> <FaLocationDot id="location" /> Dubai Al Warsan 3, Birds and Pets Market</p>
                    </div>
                </div>
                <div>
                    <button>Book Now</button>
                </div>
                </Link>

            </div>

            <div className="avenue_pet">
            <Link to="/">
                <div className="pet_avenue">
                    <img src={grooimg_firstimg} />
                    <p>20% OFF</p>

                </div>
                <div className="rightside_list">
                    <div className="add_review">
                        <ul>
                            <li><FaStar /></li>
                            <li><FaStar /></li>
                            <li><FaStar /></li>
                            <li><FaStar /></li>
                            <li><FaRegStarHalfStroke /></li>

                        </ul>
                        <span>4.2</span>
                    </div>
                    <h1 className="my-0">Pet Avenue</h1>
                    <p className="mx-0">It is a long established fact that a reader will be distracted by content...</p>
                    <div>
                        <p className="mx-0"><span className="shop_info">Dubai</span> <GoDotFill id="dots" />          Open (10:00 AM - 07:00 PM) </p>
                        <p className="mx-0"> <FaLocationDot id="location" /> Dubai Al Warsan 3, Birds and Pets Market</p>
                    </div>
                </div>
                <div>
                    <button>Book Now</button>
                </div>
                </Link>

            </div>

            <div className="avenue_pet">
            <Link to="/">
                <div className="pet_avenue">
                    <img src={grooimg_firstimg} />
                    <p>20% OFF</p>

                </div>
                <div className="rightside_list">
                    <div className="add_review">
                        <ul>
                            <li><FaStar /></li>
                            <li><FaStar /></li>
                            <li><FaStar /></li>
                            <li><FaStar /></li>
                            <li><FaRegStarHalfStroke /></li>

                        </ul>
                        <span>4.2</span>
                    </div>
                    <h1 className="my-0">Pet Avenue</h1>
                    <p className="mx-0">It is a long established fact that a reader will be distracted by content...</p>
                    <div>
                        <p className="mx-0"><span className="shop_info">Dubai</span> <GoDotFill id="dots" />          Open (10:00 AM - 07:00 PM) </p>
                        <p className="mx-0"> <FaLocationDot id="location" /> Dubai Al Warsan 3, Birds and Pets Market</p>
                    </div>
                </div>
                <div>
                    <button>Book Now</button>
                </div>
                </Link>

            </div>

            <div className="avenue_pet">
            <Link to="/">
                <div className="pet_avenue">
                    <img src={grooimg_firstimg} />
                    <p>20% OFF</p>

                </div>
                <div className="rightside_list">
                    <div className="add_review">
                        <ul>
                            <li><FaStar /></li>
                            <li><FaStar /></li>
                            <li><FaStar /></li>
                            <li><FaStar /></li>
                            <li><FaRegStarHalfStroke /></li>

                        </ul>
                        <span>4.2</span>
                    </div>
                    <h1 className="my-0">Pet Avenue</h1>
                    <p className="mx-0">It is a long established fact that a reader will be distracted by content...</p>
                    <div>
                        <p className="mx-0"><span className="shop_info">Dubai</span> <GoDotFill id="dots" />          Open (10:00 AM - 07:00 PM) </p>
                        <p className="mx-0"> <FaLocationDot id="location" /> Dubai Al Warsan 3, Birds and Pets Market</p>
                    </div>
                </div>
                <div>
                    <button>Book Now</button>
                </div>
                </Link>

            </div>

            <div className="avenue_pet">
            <Link to="/">
                <div className="pet_avenue">
                    <img src={grooimg_firstimg} />
                    <p>20% OFF</p>

                </div>
                <div className="rightside_list">
                    <div className="add_review">
                        <ul>
                            <li><FaStar /></li>
                            <li><FaStar /></li>
                            <li><FaStar /></li>
                            <li><FaStar /></li>
                            <li><FaRegStarHalfStroke /></li>

                        </ul>
                        <span>4.2</span>
                    </div>
                    <h1 className="my-0">Pet Avenue</h1>
                    <p className="mx-0">It is a long established fact that a reader will be distracted by content...</p>
                    <div>
                        <p className="mx-0"><span className="shop_info">Dubai</span> <GoDotFill id="dots" />          Open (10:00 AM - 07:00 PM) </p>
                        <p className="mx-0"> <FaLocationDot id="location" /> Dubai Al Warsan 3, Birds and Pets Market</p>
                    </div>
                </div>
                <div>
                    <button>Book Now</button>
                </div>
                </Link>

            </div>

            <div className="avenue_pet">
            <Link to="/">
                <div className="pet_avenue">
                    <img src={grooimg_firstimg} />
                    <p>20% OFF</p>

                </div>
                <div className="rightside_list">
                    <div className="add_review">
                        <ul>
                            <li><FaStar /></li>
                            <li><FaStar /></li>
                            <li><FaStar /></li>
                            <li><FaStar /></li>
                            <li><FaRegStarHalfStroke /></li>

                        </ul>
                        <span>4.2</span>
                    </div>
                    <h1 className="my-0">Pet Avenue</h1>
                    <p className="mx-0">It is a long established fact that a reader will be distracted by content...</p>
                    <div>
                        <p className="mx-0"><span className="shop_info">Dubai</span> <GoDotFill id="dots" />          Open (10:00 AM - 07:00 PM) </p>
                        <p className="mx-0"> <FaLocationDot id="location" /> Dubai Al Warsan 3, Birds and Pets Market</p>
                    </div>
                </div>
                <div>
                    <button>Book Now</button>
                </div>
                </Link>

            </div>

            <div className="avenue_pet">
            <Link to="/">
                <div className="pet_avenue">
                    <img src={grooimg_firstimg} />
                    <p>20% OFF</p>

                </div>
                <div className="rightside_list">
                    <div className="add_review">
                        <ul>
                            <li><FaStar /></li>
                            <li><FaStar /></li>
                            <li><FaStar /></li>
                            <li><FaStar /></li>
                            <li><FaRegStarHalfStroke /></li>

                        </ul>
                        <span>4.2</span>
                    </div>
                    <h1 className="my-0">Pet Avenue</h1>
                    <p className="mx-0">It is a long established fact that a reader will be distracted by content...</p>
                    <div>
                        <p className="mx-0"><span className="shop_info">Dubai</span> <GoDotFill id="dots" />          Open (10:00 AM - 07:00 PM) </p>
                        <p className="mx-0"> <FaLocationDot id="location" /> Dubai Al Warsan 3, Birds and Pets Market</p>
                    </div>
                </div>
                <div>
                    <button>Book Now</button>
                </div>
                </Link>

            </div>
          </div>
        </div>
        </>
    )
}
export default Adoption_list;