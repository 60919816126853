import React from "react";
import { Link } from "react-router-dom";
import { logo } from "./Images";
import { GoBellFill } from "react-icons/go";
import { FaShoppingCart } from "react-icons/fa";
import { FaBell } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { FaAngleDown } from "react-icons/fa6";
import { CiGlobe } from "react-icons/ci";
import { useState } from 'react';
import Cartpop from "./Cartpop";
import LoginNew from "./login/loginnew";
import "./common.css";
const Header = () => {
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);

  const handleOpenLoginModal = () => {
    setLoginModalOpen(true);
  };

  const handleCloseLoginModal = () => {
    setLoginModalOpen(false);
  };


  const [show, setShow] = useState(false);
  const handleShow = () => setShow(!show);
  return (
    <>
      <div className="header">
        <div className="container">
          <div className="row">
            <div className="col-md-4">

              <div className="">
                <div className="logo">
                  <Link to="/"><img src={logo} alt="mainlogo" /></Link>
                </div>
                <div className="store_location">
                  <ul className="location">
                    <li className="drop_down_has"><FaLocationDot id="location" /> Location <br></br>Sarojini Park/ Delhi <FaAngleDown id="dropdown_angeldown" />
                      <ul className="drop_down">
                        <li><Link to="/">LocationSarojini </Link></li>
                        <li><Link to="/">LocationSarojini </Link></li>
                        <li><Link to="/">LocationSarojini</Link> </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div>
                <ul className="menu_list">
                  <li><Link to="">About Us</Link></li>
                  <li><Link to="">Services</Link></li>
                  {/* <li><Link to="">Shop</Link></li> */}
                  <li><Link to="">Support</Link></li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 px-0">
              <div className="d-flex">
                <div className="">
                  <ul className="web" id="language_drop">
                    <li className="drop_down_has" ><CiGlobe /> Eng <FaAngleDown />
                      <ul className="drop_down">
                        <li><Link>Hin</Link></li>
                        <li><Link>Eng</Link></li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div className="bell_icons">
                  <GoBellFill />
                  <span>1</span>
                </div>
                <div className="bell_icons" onClick={handleShow}>
                  <FaShoppingCart />
                  <span>1</span>
                </div>
                <div onClick={handleOpenLoginModal}>
                  <button className="btn"><Link>Sign Up/</Link> <Link>Sign In</Link></button>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <marquee width="100%" direction="left" behavior="scroll" scrollamount="4">
                <p className="code">As our gift to you, enjoy 20% off with code XMAS20.</p>
              </marquee>

            </div>
          </div>
        </div>
      </div>
      {show &&
        <Cartpop></Cartpop>
      }

      <LoginNew isOpen={isLoginModalOpen} onClose={handleCloseLoginModal} />
    </>

  );
};

export default Header;
