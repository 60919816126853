import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { FaLocationDot } from "react-icons/fa6";
import { FaStar } from "react-icons/fa";
import { testimonial_img } from "../../components/Images";
import { grooimg_secondimg } from "../../components/Images";
import { grooimg_thirdtimg } from "../../components/Images";
import { grooimg_fourthimg } from "../../components/Images";
import { FaRegStarHalfStroke } from "react-icons/fa6";
import { Link } from "react-router-dom";
const Pet_infoslider = ()=>{
    var settings = {
        dots: false,
        arrow:true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        Margin:'10',
        Padding: '10',
        cssEase: 'linear',
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 768, 
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
          ]
      };
      return(
        <>
          <Slider {...settings}>
          
          <div className="slide-item">
            <div className="grooming_board booked_services">
             
                <div className="grooming_board_cardbox">  
                        <p>Selected</p>
                    <img src={testimonial_img} />
                            
                </div>
                <div>
                <h1 className="location_heading">Skudo</h1>
                <div className="location_creation">
                <Link to="/">
                    <p className="mx-0"><span>Age</span> : 1 year 8 months</p>
                    <p className="mx-0"><span>Gender</span>  : Male</p>
                    <p className="mx-0"><span>Breed</span> : Mixed</p>
                    <p className="mx-0"><span>Location</span> : Dubai</p>
                    
                    </Link>
                  
                </div>
              
              </div>
            </div>
           </div>
           <div className="slide-item">
           <div className="grooming_board booked_services">
             
             <div className="grooming_board_cardbox">  
                     <p>Selected</p>
                 <img src={testimonial_img} />
                         
             </div>
             <div>
             <h1 className="location_heading">Skudo</h1>
             <div className="location_creation">
             <Link to="/">
                 <p className="mx-0"><span>Age</span> : 1 year 8 months</p>
                 <p className="mx-0"><span>Gender</span>  : Male</p>
                 <p className="mx-0"><span>Breed</span> : Mixed</p>
                 <p className="mx-0"><span>Location</span> : Dubai</p>
                 
                 </Link>
               
             </div>
           
           </div>
         </div>
           </div>
           <div className="slide-item">
           <div className="grooming_board booked_services">
             
             <div className="grooming_board_cardbox">  
                     <p>Selected</p>
                 <img src={testimonial_img} />
                         
             </div>
             <div>
             <h1 className="location_heading">Skudo</h1>
             <div className="location_creation">
             <Link to="/">
                 <p className="mx-0"><span>Age</span> : 1 year 8 months</p>
                 <p className="mx-0"><span>Gender</span>  : Male</p>
                 <p className="mx-0"><span>Breed</span> : Mixed</p>
                 <p className="mx-0"><span>Location</span> : Dubai</p>
                 
                 </Link>
               
             </div>
           
           </div>
         </div>
           </div>
           <div className="slide-item">
           <div className="grooming_board booked_services">
             <div className="grooming_board_cardbox">      
             <img src={testimonial_img} />
             <div className="add_review">
                 <ul>
                     <li><FaStar /></li>
                     <li><FaStar /></li>
                     <li><FaStar /></li>
                     <li><FaStar /></li>
                     <li><FaRegStarHalfStroke /></li>
                    
                 </ul>
                 <span>4.2</span>
             </div>
             </div>
             <div>
             <h1 className="location_heading">Pet Avenue</h1>
             <div className="location_creation">
             <Link to="/">
                 <p className="mx-0">Dubai Al Warsan 3, Birds and Pets Market</p>
                 <p className="mx-0">AED 5.00</p>
                 </Link>
                 <button className="adopt_now">Repeat Service</button>
             </div>
           
           </div>
         </div>
           </div>
        
          
         
   </Slider>
        </>
      )
}

export default Pet_infoslider;
