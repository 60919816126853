
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { FaStar, FaRegStarHalfStroke, FaLocationDot } from "react-icons/fa6";
import { dog_listone, dog_listfour, doglist_three, grooimg_firstimg } from "../components/Images";
import Appdownaload from "../Home/Appdowanload";
import Sidebar from "../components/sidebar";
import Booked_Serviceslider from "./Booked_Serviceslider";
const PetShop_storedetails = () => {
    return (
        <>
            <Header />
            <div className="adoption_info">
                <div className="container mt-5">
                    <div className="row my-4">
                        <div className="col-md-2 px-0">
                            <div className="pet_avenue">
                                <img src={dog_listone} />
                                <p>20% OFF</p>

                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="rightside_list">
                                <div className="add_review">
                                    <ul>
                                        <li><FaStar /></li>
                                        <li><FaStar /></li>
                                        <li><FaStar /></li>
                                        <li><FaStar /></li>
                                        <li><FaRegStarHalfStroke /></li>

                                    </ul>
                                    <span>4.2</span>
                                </div>
                                <h1>Pet Avenue</h1>
                                <p className="mx-0">It is a long established fact that a reader will be distracted by content...</p>
                                <div>

                                    <p className="mx-0"> <FaLocationDot id="location" /> Dubai Al Warsan 3, Birds and Pets Market</p>
                                    <p className="mx-0"><span className="shop_info">Working Days : </span> 5 Days (Mon, Tue, Wed, Thr , Fri, Sat )</p>
                                    <p className="mx-0"><span className="shop_info">Working Hours : </span> 08:00 AM - 18:00 PM</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 px-0">
                            <button className="textend"><Link to="/">Get Direction</Link></button>
                        </div>
                        <div className="col-md-12 px-0">
                            <h1>Description</h1>
                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
                        </div>
                        <div className="col-md-12 px-0">
                            <h1>Centre Facilities:</h1>
                            <ul className="ceter_fac">
                                <li>1. Indoor play area</li>
                                <li>1. Indoor play area</li>
                                <li>1. Indoor play area</li>
                                <li>1. Indoor play area</li>
                            </ul>
                        </div>
                    </div>

                </div>

            </div>

            <div className="">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <Sidebar></Sidebar>
                        </div>
                        <div className="col-md-9 grooming_info">
                            <div className="features_grooming ">
                                <h1 className="main_heading" >Previous Booked Services</h1>
                            </div>
                            <Booked_Serviceslider></Booked_Serviceslider>
                            <div className="features_grooming ">
                                <h1 className="main_heading" ><span>Pet Shop </span> Service</h1>
                                <p className="sub_heading">It is a long established fact that a reader will be distracted by the readable content</p>
                            </div>


                            <div className="avenue_pet">
                                <Link to="/">
                                    <div className="pet_avenue">
                                        <img src={grooimg_firstimg} />
                                    </div>
                                    <div className="rightside_list">
                                        <div className="add_review">
                                            <ul>
                                                <li><FaStar /></li>
                                                <li><FaStar /></li>
                                                <li><FaStar /></li>
                                                <li><FaStar /></li>
                                                <li><FaRegStarHalfStroke /></li>

                                            </ul>
                                            <span>4.2</span>
                                        </div>
                                        <h1>Basic Grooming For Single Dog </h1>
                                        <p className="mx-0">It is a long established fact that a reader will be distracted by content...</p>
                                        <div className="connect_btn">
                                            <button className="adopt_now"><Link to="/">In Store </Link></button>
                                            <button className="adopt_now"><Link to="/">Mobile</Link></button>
                                        </div>
                                        <p> AED 50   <span><strike>AED65.50</strike></span></p>
                                        <div>

                                        </div>
                                    </div>
                                    <div>
                                        <button className="booknow_service">Book Now</button>
                                    </div>
                                </Link>

                            </div>

                        </div>
                    </div>
                </div>

            </div>

            <div className="app_dowanlad">
                <Appdownaload></Appdownaload>
            </div>



            <Footer />
        </>
    )
}
export default PetShop_storedetails;