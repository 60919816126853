import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { mainslider } from "../components/Images";
import {left_side} from "../components/Images";
const Mainslider = ()=>{
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
    autoplaySpeed: 3000,
      };
    return(
        <>
           <Slider {...settings}>
          
          <div className="slide-item">
          <img src={mainslider} className="slidermain_img" />
          <div className="main_slidercontent">
            {/* <img src={left_side}  className="left_side"/> */}
            <div>
            <p>Making  <span className="your">your</span></p>
            <h1>DOGS <sapn className="happy">HAPPY!</sapn> </h1>
            <img src=""/>
            </div>
        
          <h1 className="animation_text">DOGS <sapn className="happy">HAPPY!</sapn> </h1>
          </div>
        
           </div>
           <div className="slide-item">
           <img src={mainslider}  className="slidermain_img" />
           <div className="main_slidercontent">
            {/* <img src={left_side}  className="left_side"/> */}
          <p>Making  <span className="your">your</span></p>
          <h1>DOGS <sapn className="happy">HAPPY!</sapn> </h1>
          <h1 className="animation_text">DOGS <sapn className="happy">HAPPY!</sapn> </h1>
          <img src=""/>
          </div>
           </div>
           <div className="slide-item">
           <img src={mainslider}  className="slidermain_img" />
           <div className="main_slidercontent">
            {/* <img src={left_side}  className="left_side"/> */}
          <p>Making  <span className="your">your</span></p>
          <h1>DOGS <sapn className="happy">HAPPY!</sapn> </h1>
          <h1 className="animation_text">DOGS <sapn className="happy">HAPPY!</sapn> </h1>
          <img src=""/>
          </div>
           </div>
          
         
   </Slider>
        </>
    )
}
export default Mainslider;
