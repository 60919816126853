import { PeTfriendly } from "../components/Images";
import { FaStar, FaRegStarHalfStroke, FaLocationDot } from "react-icons/fa6";
import { Link } from "react-router-dom";
import Petstore_friendlydetails from "./Petstore_friendlydetails";
const PetfriendlyList = () => {
    return (
        <>
         <div className="pet_listing_innersection row">
            <div className="col-md-4">
                <div className="grooming_board mx-0">
                    <Link to="/petstore-friendlydetails">
                    
                    <div className="grooming_board_cardbox">
                        <img src={PeTfriendly} />
                        <div className="add_review">
                            <ul>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaRegStarHalfStroke /></li>
                            </ul>
                            <span>4.2</span>
                        </div>
                    </div>
                    <div className="pet_info">
                        <h1 className="location_heading">Pet Avenue</h1>
                        <p className="pet_details mx-0">It is a long established fact that a reader will be distracted by content...</p>
                        <div className="location_creation">
                            <Link to="">
                                <FaLocationDot id="location" />
                                <p className="locationvenue"> Dubai Al Warsan 3, Birds...</p>
                            </Link>
                        </div>
                    </div>
                    </Link>
                </div>
            </div>

            <div className="col-md-4">
            <div className="grooming_board mx-0">
             
             <div className="grooming_board_cardbox"> 
                 <div className="offer_discount">
                     <h1>20%</h1> <p>off</p>
                     </div>         
             <img src={PeTfriendly} />
             <div className="add_review">
                 <ul>
                     <li><FaStar /></li>
                     <li><FaStar /></li>
                     <li><FaStar /></li>
                     <li><FaStar /></li>
                     <li><FaRegStarHalfStroke /></li>
                    
                 </ul>
                 <span>4.2</span>
             </div>
             </div>
             <div className="pet_info">
                        <h1 className="location_heading">Pet Avenue</h1>
                        <p className="pet_details mx-0">It is a long established fact that a reader will be distracted by content...</p>
                        <div className="location_creation">
                            <Link to="">
                                <FaLocationDot id="location" />
                                <p className="locationvenue"> Dubai Al Warsan 3, Birds...</p>
                            </Link>
                        </div>
                    </div>
         </div>
            </div>

            <div className="col-md-4">
                <div className="grooming_board mx-0">
                    <div className="grooming_board_cardbox">
                        <img src={PeTfriendly} />
                        <div className="add_review">
                            <ul>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaRegStarHalfStroke /></li>
                            </ul>
                            <span>4.2</span>
                        </div>
                    </div>
                    <div className="pet_info">
                        <h1 className="location_heading">Pet Avenue</h1>
                        <p className="pet_details mx-0">It is a long established fact that a reader will be distracted by content...</p>
                        <div className="location_creation">
                            <Link to="">
                                <FaLocationDot id="location" />
                                <p className="locationvenue"> Dubai Al Warsan 3, Birds...</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4">
                <div className="grooming_board mx-0">
                    <div className="grooming_board_cardbox">
                        <img src={PeTfriendly} />
                        <div className="add_review" id="addreviews">
                            <ul>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaRegStarHalfStroke /></li>
                            </ul>
                            <span>4.2</span>
                        </div>
                    </div>
                    <div className="pet_info">
                        <h1 className="location_heading">Pet Avenue</h1>
                        <p className="pet_details mx-0">It is a long established fact that a reader will be distracted by content...</p>
                        <div className="location_creation">
                            <Link to="">
                                <FaLocationDot id="location" />
                                <p className="locationvenue"> Dubai Al Warsan 3, Birds...</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4">
            <div className="grooming_board mx-0">
             
             <div className="grooming_board_cardbox"> 
                 <div className="offer_discount">
                     <h1>20%</h1> <p>off</p>
                     </div>         
             <img src={PeTfriendly} />
             <div className="add_review">
                 <ul>
                     <li><FaStar /></li>
                     <li><FaStar /></li>
                     <li><FaStar /></li>
                     <li><FaStar /></li>
                     <li><FaRegStarHalfStroke /></li>
                    
                 </ul>
                 <span>4.2</span>
             </div>
             </div>
             <div className="pet_info">
                        <h1 className="location_heading">Pet Avenue</h1>
                        <p className="pet_details mx-0">It is a long established fact that a reader will be distracted by content...</p>
                        <div className="location_creation">
                            <Link to="">
                                <FaLocationDot id="location" />
                                <p className="locationvenue"> Dubai Al Warsan 3, Birds...</p>
                            </Link>
                        </div>
                    </div>
         </div>
            </div>

            <div className="col-md-4">
                <div className="grooming_board mx-0">
                    <div className="grooming_board_cardbox">
                        <img src={PeTfriendly} />
                        <div className="add_review">
                            <ul>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaRegStarHalfStroke /></li>
                            </ul>
                            <span>4.2</span>
                        </div>
                    </div>
                    <div className="pet_info">
                        <h1 className="location_heading">Pet Avenue</h1>
                        <p className="pet_details mx-0">It is a long established fact that a reader will be distracted by content...</p>
                        <div className="location_creation">
                            <Link to="">
                                <FaLocationDot id="location" />
                                <p className="locationvenue"> Dubai Al Warsan 3, Birds...</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default PetfriendlyList;