import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { FaLocationDot } from "react-icons/fa6";
import { FaStar } from "react-icons/fa";
import { PeTfriendly } from "../components/Images";
import { FaRegStarHalfStroke } from "react-icons/fa6";
import { Link } from "react-router-dom";
const Petslider = ()=>{
    var settings = {
        dots: false,
        arrow:true,
        arrow:false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        Margin:'10',
        Padding: '10',
        cssEase: 'linear',
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 768, 
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
          ]
      };
      return(
        <>
          <Slider {...settings}>
          
          <div className="slide-item">
          <div className="grooming_board mx-0">
                    <div className="grooming_board_cardbox">
                        <img src={PeTfriendly} />
                        <div className="add_review">
                            <ul>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaRegStarHalfStroke /></li>
                            </ul>
                            <span>4.2</span>
                        </div>
                    </div>
                    <div className="pet_info">
                        <h1 className="location_heading">Pet Avenue</h1>
                        <p className="pet_details mx-0">It is a long established fact that a reader will be distracted by content...</p>
                        <div className="location_creation">
                            <Link to="">
                                <FaLocationDot id="location" />
                                <p className="locationvenue"> Dubai Al Warsan 3, Birds...</p>
                            </Link>
                        </div>
                    </div>
                </div>
           </div>
           <div className="slide-item">
           <div className="grooming_board mx-0">
                    <div className="grooming_board_cardbox">
                        <img src={PeTfriendly} />
                        <div className="add_review">
                            <ul>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaRegStarHalfStroke /></li>
                            </ul>
                            <span>4.2</span>
                        </div>
                    </div>
                    <div className="pet_info">
                        <h1 className="location_heading">Pet Avenue</h1>
                        <p className="pet_details mx-0">It is a long established fact that a reader will be distracted by content...</p>
                        <div className="location_creation">
                            <Link to="">
                                <FaLocationDot id="location" />
                                <p className="locationvenue"> Dubai Al Warsan 3, Birds...</p>
                            </Link>
                        </div>
                    </div>
                </div>
           </div>
           <div className="slide-item">
           <div className="grooming_board mx-0">
                    <div className="grooming_board_cardbox">
                        <img src={PeTfriendly} />
                        <div className="add_review">
                            <ul>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaRegStarHalfStroke /></li>
                            </ul>
                            <span>4.2</span>
                        </div>
                    </div>
                    <div className="pet_info">
                        <h1 className="location_heading">Pet Avenue</h1>
                        <p className="pet_details mx-0">It is a long established fact that a reader will be distracted by content...</p>
                        <div className="location_creation">
                            <Link to="">
                                <FaLocationDot id="location" />
                                <p className="locationvenue"> Dubai Al Warsan 3, Birds...</p>
                            </Link>
                        </div>
                    </div>
                </div>
           </div>
           <div className="slide-item">
           <div className="grooming_board mx-0">
                    <div className="grooming_board_cardbox">
                        <img src={PeTfriendly} />
                        <div className="add_review">
                            <ul>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaRegStarHalfStroke /></li>
                            </ul>
                            <span>4.2</span>
                        </div>
                    </div>
                    <div className="pet_info">
                        <h1 className="location_heading">Pet Avenue</h1>
                        <p className="pet_details mx-0">It is a long established fact that a reader will be distracted by content...</p>
                        <div className="location_creation">
                            <Link to="">
                                <FaLocationDot id="location" />
                                <p className="locationvenue"> Dubai Al Warsan 3, Birds...</p>
                            </Link>
                        </div>
                    </div>
                </div>
           </div>
           <div className="slide-item">
           <div className="grooming_board mx-0">
                    <div className="grooming_board_cardbox">
                        <img src={PeTfriendly} />
                        <div className="add_review">
                            <ul>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaStar /></li>
                                <li><FaRegStarHalfStroke /></li>
                            </ul>
                            <span>4.2</span>
                        </div>
                    </div>
                    <div className="pet_info">
                        <h1 className="location_heading">Pet Avenue</h1>
                        <p className="pet_details mx-0">It is a long established fact that a reader will be distracted by content...</p>
                        <div className="location_creation">
                            <Link to="">
                                <FaLocationDot id="location" />
                                <p className="locationvenue"> Dubai Al Warsan 3, Birds...</p>
                            </Link>
                        </div>
                    </div>
                </div>
           </div>
          
         
   </Slider>
        </>
      )
}

export default Petslider;
