import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { product_pic } from "../components/Images";
import { FaStar } from "react-icons/fa";
import { FaRegStarHalfStroke } from "react-icons/fa6";
const Frequentelyslider = () => {
  var settings = {
    dots: false,
    arrow: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    Margin: '10',
    Padding: '10',
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };
  return (
    <>
      <Slider {...settings}>

        <div className="slide-item">
          <div className="grooming_board">
            <div className="grooming_board_productcard">
              <div className="img_container">
                <img src={product_pic} alt="product_img" />
              </div>


              <div className="add_review">
                <ul>
                  <li><FaStar /></li>
                  <li><FaStar /></li>
                  <li><FaStar /></li>
                  <li><FaStar /></li>
                  <li><FaRegStarHalfStroke /></li>

                </ul>
                <span>4.2</span>
              </div>
              <h1>Pet Avenue</h1>
              <div className="product_size">
                <p className="gram_productsize">500 g</p>
                <p className="kilo_productsize">1kg</p>
              </div>
              <div className="product_price">
                <p>AED 500</p>
                <strike>AED65.50</strike>
              </div>
              <button>Add To Cart</button>
            </div>
          </div>
        </div>
        <div className="slide-item">
          <div className="grooming_board">
            <div className="grooming_board_productcard">
              <div className="img_container">
                <img src={product_pic} alt="product_img" />
              </div>


              <div className="add_review">
                <ul>
                  <li><FaStar /></li>
                  <li><FaStar /></li>
                  <li><FaStar /></li>
                  <li><FaStar /></li>
                  <li><FaRegStarHalfStroke /></li>

                </ul>
                <span>4.2</span>
              </div>
              <h1>Pet Avenue</h1>
              <div className="product_size">
                <p className="gram_productsize">500 g</p>
                <p className="kilo_productsize">1kg</p>
              </div>
              <div className="product_price">
                <p>AED 500</p>
                <strike>AED65.50</strike>
              </div>
              <button>Add To Cart</button>
            </div>
          </div>
        </div>
        <div className="slide-item">
          <div className="grooming_board">
            <div className="grooming_board_productcard">
              <div className="img_container">
                <img src={product_pic} alt="product_img" />
              </div>


              <div className="add_review">
                <ul>
                  <li><FaStar /></li>
                  <li><FaStar /></li>
                  <li><FaStar /></li>
                  <li><FaStar /></li>
                  <li><FaRegStarHalfStroke /></li>

                </ul>
                <span>4.2</span>
              </div>
              <h1>Pet Avenue</h1>
              <div className="product_size">
                <p className="gram_productsize">500 g</p>
                <p className="kilo_productsize">1kg</p>
              </div>
              <div className="product_price">
                <p>AED 500</p>
                <strike>AED65.50</strike>
              </div>
              <button>Add To Cart</button>
            </div>
          </div>
        </div>
        <div className="slide-item">
          <div className="grooming_board">
            <div className="grooming_board_productcard">
              <div className="img_container">
                <img src={product_pic} alt="product_img" />
              </div>


              <div className="add_review">
                <ul>
                  <li><FaStar /></li>
                  <li><FaStar /></li>
                  <li><FaStar /></li>
                  <li><FaStar /></li>
                  <li><FaRegStarHalfStroke /></li>

                </ul>
                <span>4.2</span>
              </div>
              <h1>Pet Avenue</h1>
              <div className="product_size">
                <p className="gram_productsize">500 g</p>
                <p className="kilo_productsize">1kg</p>
              </div>
              <div className="product_price">
                <p>AED 500</p>
                <strike>AED65.50</strike>
              </div>
              <button>Add To Cart</button>
            </div>
          </div>
        </div>
        <div className="slide-item">
          <div className="grooming_board">
            <div className="grooming_board_productcard">
              <div className="img_container">
                <img src={product_pic} alt="product_img" />
              </div>


              <div className="add_review">
                <ul>
                  <li><FaStar /></li>
                  <li><FaStar /></li>
                  <li><FaStar /></li>
                  <li><FaStar /></li>
                  <li><FaRegStarHalfStroke /></li>

                </ul>
                <span>4.2</span>
              </div>
              <h1>Pet Avenue</h1>
              <div className="product_size">
                <p className="gram_productsize">500 g</p>
                <p className="kilo_productsize">1kg</p>
              </div>
              <div className="product_price">
                <p>AED 500</p>
                <strike>AED65.50</strike>
              </div>
              <button>Add To Cart</button>
            </div>
          </div>
        </div>


      </Slider>
    </>
  )
}

export default Frequentelyslider;
