import React from 'react';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./productslider.css";

import { productsliderfirst, productslidersecond, productsliderthird, productsliderfourth } from "../components/Images";

const images = [
  {
    original: productsliderfirst,   
    thumbnail: productsliderfirst, 
    description:"20% Off", 
  },
  {
    original: productslidersecond,  
    thumbnail: productslidersecond, 
    description:"20% Off", 
  },
  {
    original: productsliderthird,   
    thumbnail: productsliderthird,  
  },
  {
    original: productsliderfourth,  
    thumbnail: productsliderfourth, 
    description:"20% Off ", 
  },
];

function Productslider() {
  return (
    <div className="gallery">
      <ImageGallery 
        showPlayButton={false} 
        slideDuration={1000} 
        autoPlay={false}
        showFullscreenButton={false}
        showNav={false} 
        items={images} 
      />
    </div>
  );
}

export default Productslider;
