import React, { useState } from "react";
import { FaUser, FaPhone, FaEnvelope } from "react-icons/fa";
import Header from "../Header";
import Footer from "../Footer";
import { Link, useNavigate } from "react-router-dom";
import { IoCall } from "react-icons/io5";

function Addnew_address() {
  const navigate = useNavigate();

  const store_process = () => {
    navigate("/delivery-process-item-deatils");
  };
  return (
    <>
      <Header></Header>
      <div className="container my-4 mt-5">
        <div className="row">
          <div className="col-md-9">
            <ol className="steps_store_personal_deatils">
              <li className="step1 current progress_bar_first_step">
                <span className="progress_bar_first_step_span">
                  Personal Details
                </span>
              </li>
              <li className="step1 current progress_bar_first_step_second">
                <span className="progress_bar_first_step_second_span">
                  Checkout
                </span>
              </li>
              <li className="step1 current progress_bar_first_step_third">
                <span className="progress_bar_first_step_third_span">
                  Payment
                </span>
              </li>
            </ol>
            <div className="store_process_payement_deatils_heading mt-2">
              <h6>You are logged in as:</h6>
            </div>
            <div className=" p-2 rounded user-info-container store_process_heading_name_main_div">
              <div className="row align-items-center">
                <div className="col-12 col-md-4 d-flex align-items-center mb-3 mb-md-0 store_process_heading_name">
                  <FaUser className="me-2" />
                  <span>Rufus</span>
                </div>
                <div className="col-12 col-md-4 d-flex align-items-center mb-3 mb-md-0 store_process_heading_name">
                  <IoCall   className="me-2" />
                  <span>+971-XX-1234567</span>
                </div>
                <div className="col-12 col-md-4 d-flex align-items-center store_process_heading_name">
                  <FaEnvelope className="me-2" />
                  <span>dineatadm@address.com</span>
                </div>
              </div>
            </div>
            <div className="store_process_payement_deatils_heading mt-4">
              <h6>Select delivery Options</h6>
              <hr></hr>
            </div>
            <div>
              <button className="add_new_address">Add A New Address</button>

            </div>

            <div className="addnew_address my-4">
              <form>
                <div className="row">
                  <div className="col-md-4">
                    <input type="text" placeholder="First Name" className="form-field"></input>
                  </div>
                  <div className="col-md-4">
                    <input type="text" placeholder="Last Name" className="form-field"></input>
                  </div>
                  <div className="col-md-4">
                    <input type="tel" placeholder="Mobile Number" className="form-field"></input>
                  </div>
                  <div className="col-md-8">
                    <input type="email" placeholder="Email Address" className="form-field"></input>
                  </div>
                  <div className="col-md-4" >
                    <select className="form-field">
                      <option>city</option>
                      <option>Bomaby</option>

                    </select>
                  </div>
                  <div className="col-md-12">
                    <input type="text" placeholder="Address Line 1" className="form-field"></input>
                  </div>
                  <div className="col-md-12">
                    <input type="text" placeholder="Building Name" className="form-field"></input>
                  </div>
                  <div className="col-md-12">
                    <input type="text" placeholder="Apartment / Villa Name" className="form-field"></input>
                  </div>
                  <div className="col-md-12">
                    <div className="save_btn">
                      <input type="radio"></input>
                      <p className="save_info">Save your information for future checkouts.</p>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="addnew_address mb-3">
              <div>
                <input type="radio"></input>
                <span>Address 01</span>
              </div>

              <p>Rufus, +971-XX-1234567, dineatadm@address.com
                C-20/1, C Block, Phase 2, Industrial Area, Sector 62, Noida, Uttar Pradesh 201301</p>
            </div>

            <div className="addnew_address mb-3">
              <div>
                <input type="radio"></input>
                <span>Address 02</span>
              </div>

              <p>Rufus, +971-XX-1234567, dineatadm@address.com
                C-20/1, C Block, Phase 2, Industrial Area, Sector 62, Noida, Uttar Pradesh 201301</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="store_process_card_name">
              <span className="font-weight-bold">Payment Details</span>
            </div>
            <div className="payment-details store_process_payement_deatils_heading mt-2">
              <h6>Payment Details</h6>
              <div className="store_process_payment_details_heading_child_div">
                <table className="table  bg-light">
                  <tbody>
                    <tr>
                      <td>Detail</td>
                      <td className="text-end">Price</td>
                    </tr>
                    <tr>
                      <td>Subtotal</td>
                      <td className="text-end">50 AED</td>
                    </tr>
                    <tr>
                      <td>Tier Discount</td>
                      <td className="text-end text-danger">-AED 0.86</td>
                    </tr>
                    <tr>
                      <td>Total</td>
                      <td className="text-end">AED 49.14</td>
                    </tr>
                    <tr>
                      <td>Treats Used (10)</td>
                      <td className="text-end">-1</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="row mb-4 mt-4 font-weight-bold store_process_payement_deatils_heading_child_div store_process_deatils_price">
                <div className="col">Amount Due</div>
                <div className="col text-end">AED 39.14</div>
              </div>
              <div className="login_user_otp_for_bottom_button">
                <Link to="/delivery-process-item-deatils">
                  <button className="btn btn-danger btn-lg mb-3 user_otp_first" >
                    Proceed to Order
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default Addnew_address;
