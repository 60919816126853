import React from "react";
import "./createPetProfile.css";
import Header from "../Header";
import Appdownaload from "../../Home/Appdowanload";
import Footer from "../Footer";
import { useNavigate, Link } from 'react-router-dom';
const CreatePetParentName = () => {
  const navigate = useNavigate();
  const handleSubmit = () => {
    navigate('/create-verify-owner-deatils');
  }
  return (
    <>
      <Header />
      <div>
        <div className="container">
          <div className="row mt-5">
            <div className="col-lg-9 ">
              <div className="create_pet_profile_main_div_div">
                <div className="create_pet_profile_for_skip_for_now">
                  <div className="create_pet_profile_for_pet_profile">
                    <h2>
                      Enter{" "}
                      <span className="text-danger fw-bold">
                        Pet Parent Details
                      </span>
                    </h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable content
                    </p>
                  </div>
                  <div className="login_user_otp_for_bottom_button_skip">
                    <Link to="/create-enter-woner-detiail">
                      <button className="btn btn-outline-danger user_otp_second">
                        Skip for Now
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="create_pet_profile_main_section">
                  <div className="row cretae_pet_profile_form_div_sec">
                    <div className="col-lg-12 create_verify_owner_deatils_form_section">
                      <h4>Add Pet Parent Details</h4>
                      <form>
                        <div className="row mb-3 ">
                          <div className="col-md-4 mb-3 mb-md-0 create_pet_for_profile_input">
                            <input
                              type="text"
                              className="form-control store_input_field_field_for_Create_pet"
                              placeholder="Owner’s Name"
                            />
                          </div>
                          <div className="col-md-4 create_pet_for_profile_input">
                            <input
                              type="number"
                              className="form-control store_input_field_field_for_Create_pet"
                              placeholder="+971-XX-1234567"
                            />
                          </div>
                          <div className="col-md-4 create_pet_for_profile_input">
                            <select className="form-control store_input_field_field_for_Create_pet create_pet_for_gender_in_login">
                              <option>Gender</option>
                              <option>Male</option>
                              <option>Female</option>
                            </select>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-md-12 mb-3 mb-md-0 create_pet_for_profile_input">
                            <input
                              type="email"
                              className="form-control store_input_field_field_for_Create_pet"
                              placeholder="Email Address"
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 create_pet_for_profile_input">
                            <input
                              type="text"
                              className="form-control store_input_field_field_for_Create_pet"
                              placeholder="Location"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="login_user_otp_for_bottom_button_create_pet">
                  <button className="btn  btn-lg create_pet_back_button">
                    Back
                  </button>
                  <button className="btn btn-danger btn-lg  user_otp_first" >
                    Submit
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-3 p-4 create_pet_profile_right_section_get_expert text-white d-flex flex-column justify-content-center">
              <div
                style={{ backgroundColor: "#C73633" }}
                className="frisbee_main_section_div"
              >
                <div className="frisbee_main_section_div_child_create_pet_profile">
                  <span>Get Expert</span>
                  <span className="login_professional_servies">
                    Professional Services
                  </span>
                  <span>At Your Door Step.</span>
                </div>
                <img
                  src="/frisbeeImage/login.png"
                  alt="Service"
                  className="img-fluid mt-3 rounded-lg"
                />
              </div>
            </div>
          </div>
        </div>
      
        <div className="app_dowanlad">
    <Appdownaload></Appdownaload>
</div>
        <Footer></Footer>
      </div>
    </>
  );
};

export default CreatePetParentName;
