import React, { useState } from "react";
import Shop_Categories from "./Shop_Categories";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import './shopproduct_list.css';
import { dog_listone, product_pic } from "../components/Images";
import { FaStar, FaRegStarHalfStroke, FaLocationDot } from "react-icons/fa6";


const Shop_productlisting = () => {
  const [buttonText, setButtonText] = useState('Add To Cart');
  const handleButtonClick = () => {
    setButtonText('Added');
  };



  const navigate = useNavigate();
  const handle_NextPage = () => {
    navigate("/productdetail");
  };
  return (
    <>
      <Header></Header>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="adoption_info">
              <div className="container mt-5">
                <div className="row my-4">
                  <div className="col-md-2 px-0">
                    <div className="pet_avenue">
                      <img src={dog_listone} />
                      <p>20% OFF</p>

                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="rightside_list">
                      <div className="add_review">
                        <ul>
                          <li><FaStar /></li>
                          <li><FaStar /></li>
                          <li><FaStar /></li>
                          <li><FaStar /></li>
                          <li><FaRegStarHalfStroke /></li>

                        </ul>
                        <span>4.2</span>
                      </div>
                      <h1>Pet Avenue</h1>
                      <p className="mx-0">It is a long established fact that a reader will be distracted by content...</p>
                      <div>

                        <p className="mx-0"> <FaLocationDot id="location" /> Dubai Al Warsan 3, Birds and Pets Market</p>
                        <p className="mx-0"><span className="shop_info">Working Days : </span> 5 Days (Mon, Tue, Wed, Thr , Fri, Sat )</p>
                        <p className="mx-0"><span className="shop_info">Working Hours : </span> 08:00 AM - 18:00 PM</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 px-0">
                    <button className="textend"><Link to="/">Get Direction</Link></button>
                  </div>
                  <div className="col-md-12 px-0">
                    <h1>Description</h1>
                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
                  </div>
                </div>

              </div>

            </div>

          </div>

          <div className="col-md-3">
            <Shop_Categories></Shop_Categories>
          </div>
          <div className="col-md-9 grooming_info" id="clothing_page">
            <div className="features_grooming ">
              <h1 className="main_heading" >   <span>Clothing & Bedding  </span> Shop</h1>
              <p className="sub_heading" >It is a long established fact that a reader will be distracted by the readable content</p>
            </div>

            <div className="row">
              <div className="col-md-4">
                <div className="grooming_board">
                  <div className="grooming_board_productcard">
                    <div className="img_container cursor-pointer" onClick={handle_NextPage}>
                      <img src={product_pic} alt="product_img" />
                    </div>


                    <div className="add_review">
                      <ul>
                        <li><FaStar /></li>
                        <li><FaStar /></li>
                        <li><FaStar /></li>
                        <li><FaStar /></li>
                        <li><FaRegStarHalfStroke /></li>

                      </ul>
                      <span>4.2</span>
                    </div>
                    <h1 onClick={handle_NextPage} className="cursor-pointer">Pet Avenue</h1>
                    <div className="product_size">
                      <p className="gram_productsize">500 g</p>
                      <p className="kilo_productsize">1kg</p>
                    </div>
                    <div className="product_price">
                      <p>AED 500</p>
                      <strike>AED65.50</strike>
                    </div>
                    {/* <button >Add To Cart</button> */}
                    <button onClick={handleButtonClick}>
                      {buttonText}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="grooming_board">
                  <div className="grooming_board_productcard">
                    <div className="img_container">
                      <img src={product_pic} alt="product_img" />
                    </div>


                    <div className="add_review">
                      <ul>
                        <li><FaStar /></li>
                        <li><FaStar /></li>
                        <li><FaStar /></li>
                        <li><FaStar /></li>
                        <li><FaRegStarHalfStroke /></li>

                      </ul>
                      <span>4.2</span>
                    </div>
                    <h1>Pet Avenue</h1>
                    <div className="product_size">
                      <p className="gram_productsize">500 g</p>
                      <p className="kilo_productsize">1kg</p>
                    </div>
                    <div className="product_price">
                      <p>AED 500</p>
                      <strike>AED65.50</strike>
                    </div>
                    <button>Add To Cart</button>

                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="grooming_board">
                  <div className="grooming_board_productcard">
                    <div className="img_container">
                      <img src={product_pic} alt="product_img" />
                    </div>


                    <div className="add_review">
                      <ul>
                        <li><FaStar /></li>
                        <li><FaStar /></li>
                        <li><FaStar /></li>
                        <li><FaStar /></li>
                        <li><FaRegStarHalfStroke /></li>

                      </ul>
                      <span>4.2</span>
                    </div>
                    <h1>Pet Avenue</h1>
                    <div className="product_size">
                      <p className="gram_productsize">500 g</p>
                      <p className="kilo_productsize">1kg</p>
                    </div>
                    <div className="product_price">
                      <p>AED 500</p>
                      <strike>AED65.50</strike>
                    </div>
                    <button>Add To Cart</button>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="grooming_board">
                  <div className="grooming_board_productcard">
                    <div className="img_container">
                      <img src={product_pic} alt="product_img" />
                    </div>


                    <div className="add_review">
                      <ul>
                        <li><FaStar /></li>
                        <li><FaStar /></li>
                        <li><FaStar /></li>
                        <li><FaStar /></li>
                        <li><FaRegStarHalfStroke /></li>

                      </ul>
                      <span>4.2</span>
                    </div>
                    <h1>Pet Avenue</h1>
                    <div className="product_size">
                      <p className="gram_productsize">500 g</p>
                      <p className="kilo_productsize">1kg</p>
                    </div>
                    <div className="product_price">
                      <p>AED 500</p>
                      <strike>AED65.50</strike>
                    </div>
                    <button>Add To Cart</button>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="grooming_board">
                  <div className="grooming_board_productcard">
                    <div className="img_container">
                      <img src={product_pic} alt="product_img" />
                    </div>


                    <div className="add_review">
                      <ul>
                        <li><FaStar /></li>
                        <li><FaStar /></li>
                        <li><FaStar /></li>
                        <li><FaStar /></li>
                        <li><FaRegStarHalfStroke /></li>

                      </ul>
                      <span>4.2</span>
                    </div>
                    <h1>Pet Avenue</h1>
                    <div className="product_size">
                      <p className="gram_productsize">500 g</p>
                      <p className="kilo_productsize">1kg</p>
                    </div>
                    <div className="product_price">
                      <p>AED 500</p>
                      <strike>AED65.50</strike>
                    </div>
                    <button>Add To Cart</button>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="grooming_board">
                  <div className="grooming_board_productcard">
                    <div className="img_container">
                      <img src={product_pic} alt="product_img" />
                    </div>


                    <div className="add_review">
                      <ul>
                        <li><FaStar /></li>
                        <li><FaStar /></li>
                        <li><FaStar /></li>
                        <li><FaStar /></li>
                        <li><FaRegStarHalfStroke /></li>

                      </ul>
                      <span>4.2</span>
                    </div>
                    <h1>Pet Avenue</h1>
                    <div className="product_size">
                      <p className="gram_productsize">500 g</p>
                      <p className="kilo_productsize">1kg</p>
                    </div>
                    <div className="product_price">
                      <p>AED 500</p>
                      <strike>AED65.50</strike>
                    </div>
                    <button>Add To Cart</button>
                  </div>
                </div>
              </div>
            </div>
          </div>



        </div>
      </div>

      <Footer></Footer>
    </>
  )
}
export default Shop_productlisting;