
import { logo } from "../components/Images";
const Enquiery = () =>{
    return(
        <>
        <div className="enquiery">
            <div className="enquiery_form">
                <img src={logo}  className="enquiery_logo"/>
                <h1 className="main_heading">Looking for the pawfect protection for your dog? Enroll Now to</h1>
                <h2  className="main_heading">  start your Pet Insurance</h2>
                <button>Send Your Inquiry</button>
            </div>
        </div>
        </>
    )
}
export default Enquiery;