import { Link } from 'react-router-dom';
import { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import {
    service_one, mini_serviceone, miniservice_sec, miniservice_thri, healthcar, clothing,
    miniservice_fourth, miniservice_fif, miniservice_eight, accessories, grooming_hygeine
} from "../components/Images";
import { service_two } from "../components/Images";
import { service_third } from "../components/Images";
import { service_fourth } from "../components/Images";
import { service_sixth } from "../components/Images";
import { service_seventh } from "../components/Images";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { service_fifth } from "../components/Images";
import { service_eigth } from '../components/Images';
import Grooming_listing from '../Grooming_listing/Grooming_listing';
const Sidebar = () => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Services</Accordion.Header>
                    <Accordion.Body>
                        <ul className='services_names'>
                            <li>
                                <Link to="/groominglisting">
                                    <span>
                                        <img src={service_one} />
                                    </span>
                                    Grooming</Link>
                            </li>

                            <li className={open ? 'active' : ''} onClick={() => setOpen(!open)}>
                                <Link to="/">
                                    <span><img src={service_two} alt="Service 2" /></span>
                                    Pet Shop
                                </Link>
                                <MdOutlineKeyboardArrowDown
                                    className={`arrow_right drop_downsidebar ${open ? 'arrow_up' : ''}`}
                                />
                            </li>
                            {open && (
                                <ul className='nested-services'>
                                    <li>
                                        <span>
                                            <img src={clothing} />
                                        </span>
                                        <Link to="/clothing_pet_shop">Clothing & Bedding</Link></li>
                                    <li>
                                        <span>
                                            <img src={miniservice_sec} />
                                        </span>
                                        <Link to="/food_categories">Food</Link></li>
                                    <li>
                                        <span>
                                            <img src={mini_serviceone} />
                                        </span>
                                        <Link to="/toys_categories">Toys</Link></li>
                                    <li>
                                        <span>
                                            <img src={miniservice_thri} />
                                        </span>
                                        <Link to="/treat_categories">Treats, Biscuits &...</Link></li>

                                    <li>
                                        <span>
                                            <img src={grooming_hygeine} />

                                        </span>
                                        <Link to="/grooming_categories">Grooming & Hygiene</Link></li>
                                    <li>
                                        <span>
                                            <img src={healthcar} />

                                        </span>
                                        <Link to="/health_categories">Healthcare</Link></li>
                                    <li>
                                        <span>
                                            <img src={accessories} />
                                        </span>
                                        <Link to="/accessories_categories">Accessories</Link></li>
                                </ul>
                            )}
                            <li>
                                <Link to="/daycarelisting">
                                    <span>
                                        <img src={service_third} />
                                    </span>
                                    Day Care</Link>
                            </li>
                            <li>
                                <Link to="/boardinglisting">
                                    <span>
                                        <img src={service_fourth} />
                                    </span>
                                    Boarding</Link></li>

                            <li>
                                <Link to="/verterinarylisting">
                                    <span>
                                        <img src={service_sixth} />
                                    </span>
                                    Veterinary</Link>
                            </li>
                            <li>
                                <Link to="/adoption-mainpage">
                                    <span>
                                        <img src={service_seventh} />
                                    </span>
                                    Adoptions</Link>
                            </li>
                            <li>
                                <Link to="/petlisting">
                                    <span>
                                        <img src={service_fifth} />
                                    </span>
                                    Dog Friendly
                                </Link>
                            </li>
                            <li>
                                <span>
                                    <img src={service_eigth} />
                                </span>

                                <Link to="/training_listing">
                                    Training</Link></li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
                {/* <Accordion.Item eventKey="1">
                    <Accordion.Header>Fulfilment Type</Accordion.Header>
                    <Accordion.Body>
                        <ul className='services_names'>
                            <li>
                                <Link to="/">
                                    <span>
                                        <img src={service_one} />
                                    </span>
                                    Grooming</Link>
                            </li>
                            <li>
                                <Link to="/">
                                    <span>
                                        <img src={service_two} />
                                    </span>
                                    Pet Shop</Link>
                            </li>
                            <li>
                                <Link to="/">
                                    <span>
                                        <img src={service_third} />
                                    </span>
                                    Day Care</Link>
                            </li>
                            <li>
                                <Link to="/">
                                    <span>
                                        <img src={service_fourth} />
                                    </span>
                                    Boarding</Link></li>

                            <li>
                                <Link to="/">
                                    <span>
                                        <img src={service_sixth} />
                                    </span>
                                    Veterinary</Link>
                            </li>
                            <li>
                                <Link to="/">
                                    <span>
                                        <img src={service_seventh} />
                                    </span>
                                    Adoptions</Link>
                            </li>
                            <li>
                                <Link to="/">
                                    <span>
                                        <img src={service_fifth} />
                                    </span>
                                    Dog Friendly
                                </Link>
                            </li>
                            <li>
                                <span>
                                    <img src={service_eigth} />
                                </span>

                                <Link to="/">
                                    Training</Link></li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                    <Accordion.Header>Size</Accordion.Header>
                    <Accordion.Body>
                        <ul className='services_names'>
                            <li>
                                <Link to="/">
                                    <span>
                                        <img src={service_one} />
                                    </span>
                                    Grooming</Link>
                            </li>
                            <li>
                                <Link to="/">
                                    <span>
                                        <img src={service_two} />
                                    </span>
                                    Pet Shop</Link>
                            </li>
                            <li>
                                <Link to="/">
                                    <span>
                                        <img src={service_third} />
                                    </span>
                                    Day Care</Link>
                            </li>
                            <li>
                                <Link to="/">
                                    <span>
                                        <img src={service_fourth} />
                                    </span>
                                    Boarding</Link></li>

                            <li>
                                <Link to="/">
                                    <span>
                                        <img src={service_sixth} />
                                    </span>
                                    Veterinary</Link>
                            </li>
                            <li>
                                <Link to="/">
                                    <span>
                                        <img src={service_seventh} />
                                    </span>
                                    Adoptions</Link>
                            </li>
                            <li>
                                <Link to="/">
                                    <span>
                                        <img src={service_fifth} />
                                    </span>
                                    Dog Friendly
                                </Link>
                            </li>
                            <li>
                                <span>
                                    <img src={service_eigth} />
                                </span>

                                <Link to="/">
                                    Training</Link></li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                    <Accordion.Header>Offers</Accordion.Header>
                    <Accordion.Body>
                        <ul className='services_names'>
                            <li>
                                <Link to="/">
                                    <span>
                                        <img src={service_one} />
                                    </span>
                                    Grooming</Link>
                            </li>
                            <li>
                                <Link to="/">
                                    <span>
                                        <img src={service_two} />
                                    </span>
                                    Pet Shop</Link>
                            </li>
                            <li>
                                <Link to="/">
                                    <span>
                                        <img src={service_third} />
                                    </span>
                                    Day Care</Link>
                            </li>
                            <li>
                                <Link to="/">
                                    <span>
                                        <img src={service_fourth} />
                                    </span>
                                    Boarding</Link></li>

                            <li>
                                <Link to="/">
                                    <span>
                                        <img src={service_sixth} />
                                    </span>
                                    Veterinary</Link>
                            </li>
                            <li>
                                <Link to="/">
                                    <span>
                                        <img src={service_seventh} />
                                    </span>
                                    Adoptions</Link>
                            </li>
                            <li>
                                <Link to="/">
                                    <span>
                                        <img src={service_fifth} />
                                    </span>
                                    Dog Friendly
                                </Link>
                            </li>
                            <li>
                                <span>
                                    <img src={service_eigth} />
                                </span>

                                <Link to="/">
                                    Training</Link></li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item> */}
            </Accordion>
        </>
    )
}
export default Sidebar;