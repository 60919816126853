import Header from "../components/Header";
import Footer from "../components/Footer";
import MainBanner from "./mainbanner";
import Groominglisting from "./groominglisting";
import GroomingDownload from "./GroomingDownload";
import Latest_news from "../Home/Latest_news";
import Sidebar from "../components/sidebar";
import { Link } from "react-router-dom";
import "./grooming.css";
const Grooming_listing = () => {
    return (
        <>
            <Header />
            <div className="inner_mainbanner">
                <MainBanner></MainBanner>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <Sidebar></Sidebar>
                    </div>
                    <div className="col-md-9 grooming_info">
                        <div className="features_grooming ">
                            <h1 className="main_heading" >Our  <span>Grooming </span> Centres</h1>
                            <p className="sub_heading">It is a long established fact that a reader will be distracted by the readable content</p>
                        </div>
                        <Groominglisting></Groominglisting>
                    </div>
                </div>
            </div>
            <div className="grooming_download" id="groomingdownload_info">
                <GroomingDownload></GroomingDownload>
            </div>

            {/* latest news section*/}
            <div className="container">
                <div className="row my-3">
                    <div className="col-md-10 grooming_info">
                        <div className="features_grooming">
                            <h1 className="main_heading">Latest <span>News </span></h1>
                            <p className="sub_heading" >It is a long established fact that a reader will be distracted by the readable content</p>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="view_btn">
                            <button className="view_all"><Link to="/">View All Insights</Link></button>
                        </div>

                    </div>
                    <div className="col-md-12 my-3">
                        <Latest_news></Latest_news>
                    </div>


                </div>
            </div>

            <Footer />
        </>
    )
}
export default Grooming_listing;