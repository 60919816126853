import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { FaLocationDot } from "react-icons/fa6";
import { FaStar } from "react-icons/fa";
import { grooimg_firstimg } from "../components/Images";
import { grooimg_secondimg } from "../components/Images";
import { grooimg_thirdtimg } from "../components/Images";
import { grooimg_fourthimg } from "../components/Images";
import { FaRegStarHalfStroke } from "react-icons/fa6";
import { Link } from "react-router-dom";
const GroomingSlider = ()=>{
    var settings = {
        dots: false,
        arrow:true,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 4,
        slidesToScroll: 1,
        Margin:'10',
        Padding: '10',
        cssEase: 'linear',
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 768, 
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
          ]
      };
      return(
        <>
          <Slider {...settings}>
          
          <div className="slide-item">
            <div className="grooming_board">
             
                <div className="grooming_board_cardbox"> 
                    <div className="offer_discount">
                        <h1>20%</h1> <p>off</p>
                        </div>         
                <img src={grooimg_firstimg} />
                <div className="add_review">
                    <ul>
                        <li><FaStar /></li>
                        <li><FaStar /></li>
                        <li><FaStar /></li>
                        <li><FaStar /></li>
                        <li><FaRegStarHalfStroke /></li>
                       
                    </ul>
                    <span>4.2</span>
                </div>
                </div>
                <div className="">
                <h1 className="location_heading">Pet Avenue</h1>
                <div className="location_creation">
                <Link to="">
                    <FaLocationDot id="location" /> 
                    <p>Dubai Al Warsan 3, Birds and Pets Market</p>
                    </Link>
                </div>
              
              </div>
            </div>
           </div>
           <div className="slide-item">
           <div className="grooming_board">
             
             <div className="grooming_board_cardbox"> 
                 <div className="offer_discount">
                     <h1>20%</h1> <p>off</p>
                     </div>         
             <img src={grooimg_secondimg} />
             <div className="add_review">
                 <ul>
                     <li><FaStar /></li>
                     <li><FaStar /></li>
                     <li><FaStar /></li>
                     <li><FaStar /></li>
                     <li> <FaRegStarHalfStroke /></li>
                     
                 </ul>
                 <span>4.2</span>
             </div>
             </div>
             <div className="">
                <h1 className="location_heading">Pet Avenue</h1>
                <div className="location_creation">
                <Link to="">
                    <FaLocationDot id="location" /> 
                    <p>Dubai Al Warsan 3, Birds and Pets Market</p>
                    </Link>
                </div>
              
              </div>
         </div>
           </div>
           <div className="slide-item">
           <div className="grooming_board">
             
                <div className="grooming_board_cardbox"> 
                    <div className="offer_discount">
                        <h1>20%</h1> <p>off</p>
                        </div>         
                <img src={grooimg_thirdtimg} />
                <div className="add_review">
                 <ul>
                     <li><FaStar /></li>
                     <li><FaStar /></li>
                     <li><FaStar /></li>
                     <li><FaStar /></li>
                     <li> <FaRegStarHalfStroke /></li>
                     
                 </ul>
                 <span>4.2</span>
             </div>
                </div>
                <div className="">
                <h1 className="location_heading">Pet Avenue</h1>
                <div className="location_creation">
                <Link to="">
                    <FaLocationDot id="location" /> 
                    <p>Dubai Al Warsan 3, Birds and Pets Market</p>
                    </Link>
                </div>
              
              </div>
            </div>
           </div>
           <div className="slide-item">
           <div className="grooming_board">
             
             <div className="grooming_board_cardbox"> 
                 <div className="offer_discount">
                     <h1>20%</h1> <p>off</p>
                     </div>         
             <img src={grooimg_fourthimg} />
             <div className="add_review">
                 <ul>
                     <li><FaStar /></li>
                     <li><FaStar /></li>
                     <li><FaStar /></li>
                     <li><FaStar /></li>
                     <li> <FaRegStarHalfStroke /></li>
                     
                 </ul>
                 <span>4.2</span>
             </div>
             </div>
             <div className="">
                <h1 className="location_heading">Pet Avenue</h1>
                <div className="location_creation">
                <Link to="">
                    <FaLocationDot id="location" /> 
                    <p>Dubai Al Warsan 3, Birds and Pets Market</p>
                    </Link>
                </div>
              
              </div>
         </div>
           </div>
           <div className="slide-item">
           <div className="grooming_board">
             
             <div className="grooming_board_cardbox"> 
                 <div className="offer_discount">
                     <h1>20%</h1> <p>off</p>
                     </div>         
             <img src={grooimg_firstimg} />
             <div className="add_review">
                 <ul>
                     <li><FaStar /></li>
                     <li><FaStar /></li>
                     <li><FaStar /></li>
                     <li><FaStar /></li>
                     <li> <FaRegStarHalfStroke /></li>
                     
                 </ul>
                 <span>4.2</span>
             </div>
             </div>
             <div className="">
                <h1 className="location_heading">Pet Avenue</h1>
                <div className="location_creation">
                    <Link to="">
                    <FaLocationDot id="location" /> 
                    <p>Dubai Al Warsan 3, Birds and Pets Market</p>
                    </Link>
             
                </div>
              
              </div>
         </div> 
           </div>
          
         
   </Slider>
        </>
      )
}

export default GroomingSlider;
